.packagesView {
}

.packages {
  width: 88%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 300px));
  gap: 30px;
  align-items: center;
  margin-bottom: 150px;
}

.packageHelp {
  width: 88%;
  margin: 0 auto;
}
.profilePageLink{
    color: var(--brandHover);
    text-decoration: none;

}
.profilePageLink:hover{
    color: var(--brand);
    text-decoration: none;
    
}
.packageHelpTitle {
  color: var(--mainBlack);
  font-size: 1.5rem;
  /* width: 88%; */
  margin: 0 auto;
  text-align: left;
}

.packageHelpText {
  color: var(--mainBlack);
  /* width: 88%; */
  margin: 50px auto;

  padding: 30px 30px;
  background-color: whitesmoke;
}
.packagesView input {
  height: 50px;
}

.packageHelp_input {
  display: flex;
  width: 60%;
  margin: 20px 40% 0 0;
}

.packageHelp_input input {
  font-size: larger;
  color: white;
  background-color: var(--card);
  border: none;
  padding-left: 10px;
}

@media (max-width: 550px) {
  .packageHelp_input {
    display: flex;
    width: 100%;
    margin: 20px 0 0 0;
    flex-direction: column;
  }
  .packages {
    justify-content: center;
  }
}
