.navBarRow {
  display: flex;
  width: 100%;
  align-items: center;
  font-weight: 400;
  justify-content: space-between;
  background-color: var(--card);
  padding: 15px 0;

  
}
.navBar {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  align-items: center;
  color: var(--mainWhite);

  z-index: 5;
  margin: 0 auto;


  /* // new changes */
  /* grid-template-columns: 4fr 1fr;
  align-items: flex-end; */
 
}
.navBar_Primary{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 88%;
  margin: 0 auto;
  /* // new changes */
/* order: 1;
width: 100%; */

}
.navBar:first-child a {
  z-index: 9999999 !important;
}

.navBarRow_primary {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 88%;
  margin: 0 auto;
}
.navBarRow_primary li,
.navRight li {
  text-decoration: none;
  list-style: none;
  color: var(--mainWhite);
}
.navBarRow_primary li a,
.navRight li a {
  text-decoration: none;
  list-style: none;
  color: var(--mainWhite);

  position: relative;
}

.navRight {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  flex: 2;
  justify-content: flex-end;
  align-items: center;

  /* new changes */
  /* flex-direction: column;
  justify-content: center;
  align-items: center; */
}
.navRight > * + * {
    /* // new changes */
  /* margin-top: 10px !important; */
  margin-left: 10px;
}

 
@media (max-width: 991px) {
  
  .navBarRow {
    background-color: var(--mainWhite);
    padding:   0;
    /* new changes */
    /* position: relative; */


  }
  .navBarRow_primary {
   width: 100%;
  }
  
   
  .navBarRow_primary li a,
  .navRight li a {
    
    color: var(--card);
    
  }
  .navBar {
    color: var(--card);
    grid-template-columns: 1fr;

   
  }
  .navBar_Primary{
   
    display: none;
   
  }
  
  
  
}
