@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

 


 
 

body {

  font-family: 'Montserrat', sans-serif;



  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  max-width: 100vw;
}

.smallcasebold{
  font-weight: 500 !important;
}

.probold{
  font-weight: 500 !important;

}
.protrebleheavy{
  font-weight: 600 !important;

}

.protrebleextrabold{
  font-weight: 700 !important;

}

.regular{
  font-weight: 400 !important;
  
}


 


:root {
  --mainWhite: white;
  --mainBlack: #000000;
  --brand: #83BC25;


  --white: var(--mainWhite);
  --black: var(--mainBlack);

  --background: var(--brand);;
  --cardbackground: rgba(0, 0, 0, 0.6);
  --card: #110b09!important;
  --red: #ab0303;
  --disabledText: #a1a9b0;
  --mainActive:grey;

  --itemTitle: var(--brand);
  --categorytitle: #fdfdfd;

  --brandHover: #5E25BC!important;
  --brandPressed: #172bb5;
   
  --icon: hsl(209, 61%, 16%);
  --itemTitleEvent:var(--mainWhite);
  
  --mainCategoryViewAll:var(--mainWhite);
  --mainCategoryViewAllHover:var(--brandHover);

  --modalBackground: rgba(10, 10, 10, 0.5);

   
  --font64:64px;
  --font44: 44px;
  --font40: 40px;
  --font39: 39px;
  --font38: 38px;
  --font37: 37px;
  --font36: 36px;
  --font35: 35px;
  --font34: 34px;
  --font33: 33px;
  --font32: 32px;
  --font31: 31px;
  --font30: 30px;
  --font29: 29px;
  --font28: 28px;
  --font27: 27px;
  --font26: 26px;
  --font25: 25px;
  --font24: 24px;
  --font23: 23px;
  --font22: 22px;
  --font21: 21px;
  --font20: 20px;
  --font18: 18px;
  --font16: 16px;
  --font15: 15px;
  --font14: 14px;
  --font14: 14px;
  --font13: 13px;
  --font12: 12px;
  --font11: 11px;
  --font10: 10px;
  --font9: 9px;
  --font8: 8px;
  --font7: 7px;
  --font6: 6px;
  --font5: 5px;
}


/* CHANGING COLOR OF ICON https://github.com/react-icons/react-icons/issues/404 */
 .customIconLogin path{
  stroke: #83BC25 !important;
  stroke: var(--brand) !important;
 }

.itemTitleHeadingColor {
  color: white;
  color: var(--mainWhite);
}
.itemLinksColor {
  color: #83BC25;
  color: var(--brand);
}
.font-800 , .font-900{
  font-size: 44px;
  font-size: var(--font44);
  font-weight: 600;
  opacity: 1 !important;
  margin-bottom: 20px;
}
.font-900{
  font-size: 64px;
  font-size: var(--font64);

}

.font-700 {
  font-size: 36px;
  font-size: var(--font36);
}
.font-600 {
  font-size: 24px;
  font-size: var(--font24);
}

.font-500 {
  font-size: 20px;
  font-size: var(--font20);
  /* line-height: 20px; */
}

.font-400 {
  font-size: 18px;
  font-size: var(--font18);
}

.font-300 {
  font-size: 16px;
  font-size: var(--font16);
}
.font-200 {
  font-size: 14px;
  font-size: var(--font14);
}
.font-100 {
  font-size: 12px;
  font-size: var(--font12);
}
.font-000 {
  font-size: 11px;
  font-size: var(--font11);
}
/* // anumation */
.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
 
 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  


/* // raidant meida player custom note for safari
 */
 .rmp-custom-overlay {
    background: rgba(33, 33, 33, 0.85);
    color: white;
    position: absolute;
    left: 2px;
    /* top: 8px; */


    bottom: 40px;
    left: 0;
   
    
    right: 0px;
    font-size: 12px;
    /* padding: 8px; */
    text-align: center;
    /* border-radius: 4px; */
    height: 40px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.rmp-custom-overlay>div{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-top: 5px !important;
}

.rmp-custom-overlay {
    transition-property: opacity, visibility;
    transition-duration: .4s;
    transition-timing-function: ease-in;
}

.rmp-chrome .rmp-custom-overlay {
    opacity: 1;
    visibility: visible;
}

.rmp-no-chrome .rmp-custom-overlay {
    opacity: 0;
    visibility: hidden;
}
 
h2 {
  font-size: 24px;
  font-size: var(--font24);
  margin-bottom: 15px;
  display: block;
  text-align: center;
  font-weight: 400;
  padding-top: 20px;
  padding-bottom: 20px;
}
h1,
h3 {
  font-size: 36px;
  font-size: var(--font36);
  margin-bottom: 15px;
  display: block;
  text-align: center;
  font-weight: 400;
  padding-top: 20px;
}

h2::after,
h1::after {
  content: '';
  width: 120px;
  height: 4px;
  top: -1rem;
  display: block;
  background-color: #83BC25;
  background-color: var(--brand);
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  margin-top: 20px;
}
.display-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  width: 100%;
}
 
 /*  for events carousel  */
 
.carousel-item-padding-10-px{
padding: 0 10px 0 0 !important;


}
/* Editor Choice background */
.categoryItemEditorChoice {
  background-color: white;
  background-color: var(--white);
  height: 250px;
  min-height: 250px;
  max-height: 250px;
}
.categoryItem_no_margin {
  margin: 0 !important;
}
/* modal */

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 90;
  background-color: var(--cardbackground1);
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin: 0 auto;
}
.modal > h2 {
  padding: 20px;
  margin: 0;
  color: #83BC25;
  color: var(--brand);
  /* border-bottom: 1px solid #555; */
}
.smallContainerprimary{
  width: 100%;
  /* margin: 50px auto; */
}
.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
}
.copy-container {
  position: fixed;
  top: 0%;
  left: 0%;
  background: rgba(0, 0, 0, 0.5) !important;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  align-items: center;
  opacity: 0;
  pointer-events: none;
}

.copy-container.active {
  opacity: 1;
  pointer-events: all;
  background: rgba(0, 0, 0, 0.5) !important;
  z-index: 1;

 

}
.copy-popup {
  background: #000000 !important;
  background: var(--mainBlack) !important;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 12px;
  transition: -webkit-transform 1s ease;
  transition: transform 1s ease;
  transition: transform 1s ease, -webkit-transform 1s ease;
  -webkit-transform: translateY(-2rem);
          transform: translateY(-2rem);
  min-width: 30%;
  min-height: 30vh;
 
    
}
 
.copy-popup.active {
  background: #110b09 !important;
  background: var(--card) !important;

  -webkit-transform: translateY(0rem);

          transform: translateY(0rem);
}
 
.addSeecondary ul {
  -webkit-transform: none !important;
          transform: none !important;
}
 

button {
  padding: 10px 56px;
  text-decoration: none;
  border: none;
  font-size: 16px;
  font-size: var(--font16);
  border-radius: 12px;
}
.itemTitleHeading{
  color: white;
  color: var(--mainWhite);

}
button:hover {
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
button:disabled {
  background: var(--gray);
  color: var(--blueSilver);
  cursor: not-allowed;
}
button:disabled:hover {
  background: var(--gray);
  color: var(--blueSilver);
  cursor: not-allowed;
}

/* changing right and left button in banner color */
/* These are not banner carousel */
.carousel.carousel-slider .control-arrow:hover {
  color: #83BC25 !important;
  color: var(--brand) !important;
  background: rgba(0, 0, 0, 0.8) !important;
}

.carousel .slide {
  /* padding: 0 20px; */
}

.hero-container {
  width: 95%;
  margin: 0 auto;
}

/* // image wrapper for aspect-ratio 16/9
 */
.image_wrapper {
  position: relative;
  padding-bottom: 56.25%;
}

.image_wrapper img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.homePageContainer {
  width: 94%;
  margin: 0 0 0 6%;
}

.main-container {
 width: 88%;
 margin: 0 auto;
}



/* media Queeries */
@media (max-width: 850px) {
  .font-800 {
    font-size: 24px;
    font-size: var(--font24);
  }
  .font-700 {
    font-size: 22px;
    font-size: var(--font22);
  }
  .font-600 {
    font-size: 20px;
    font-size: var(--font20);
  }

  .font-500 {
    font-size: 16px;
    font-size: var(--font16);
  }

  .font-400 {
    font-size: 14px;
    font-size: var(--font14);
  }

  .font-300 {
    font-size: 13px;
    font-size: var(--font13);
  }
  .font-200 {
    font-size: 10px;
    font-size: var(--font10);
  }
  .hero-container {
    width: 100%;
  }
  .react-multiple-carousel__arrow--right {
    right: calc(4% + 1px) !important;
  }
  .react-multiple-carousel__arrow--left {
    left: calc(4% + 1px) !important;
  }
}
@media (max-width: 700px) {
  .font-800 {
    font-size: 20px;
    font-size: var(--font20);
  }
  .font-700 {
    font-size: 16px;
    font-size: var(--font16);
  }
  .font-600 {
    font-size: 14px;
    font-size: var(--font14);
  }

  .font-500 {
    font-size: 13px;
    font-size: var(--font13);
  }
}
/* media Queeries */
@media (max-width: 600px) {
  .font-800 {
    font-size: 24px;
    font-size: var(--font24);
  }
  .font-700 {
    font-size: 22px;
    font-size: var(--font22);
  }
  .font-600 {
    font-size: 20px;
    font-size: var(--font20);
  }

  .font-500 {
    font-size: 16px;
    font-size: var(--font16);
  }

  .font-400 {
    font-size: 14px;
    font-size: var(--font14);
  }

  .font-300 {
    font-size: 13px;
    font-size: var(--font13);
  }
  .font-200 {
    font-size: 10px;
    font-size: var(--font10);
  }

  /* button {
    padding: 18px 20px;
  } */
}

.nodataAvailable{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 margin-top: 50px;

}

.nodataAvailable img{
  height: 250px;
}

input:disabled{
  cursor: not-allowed;
}
/* // react-select :start */


.css-tj5bde-Svg {
 
  fill: #83BC25 !important;
 
  fill: var(--brand) !important;
  stroke-width: 0;
  line-height: 4;
}

/* .css-1s2u09g-control {
  border-radius: 1rem !important;
  height: 50px !important;
  min-height: 50px !important;
}
.css-6j8wv5-Input, {
  border-radius: 1rem !important;
  height: 50px !important;
  min-height: 50px !important;

}
.css-1pahdxg-control{
  border-radius: 1rem !important;
  height: 50px !important;
  min-height: 50px !important;

} */
/* // react-select :end */


.react-multi-carousel-item:not(.react-multi-carousel-item--active)
  .overlay_blur {
  background-image: linear-gradient(
    270deg,
    #12161d 0%,
    rgba(18, 22, 29, 0.08) 100%
  );

  opacity: 1;

  z-index: 100000;
}

.react-multi-carousel-item:not(.react-multi-carousel-item--active)
  .playicon_overlay {
  display: none;
}

/* Banner carousel */

.carousel .control-prev.control-arrow {
  left: 30px !important;
}
.carousel .control-next.control-arrow {
  right: 30px !important;
}
.react-multiple-carousel__arrow {
  padding: inherit;
  background-color: #83BC25 !important;
  background-color: var(--brand) !important;
}
.react-multiple-carousel__arrow--right {
  right: calc(1% + 1px) !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(1% + 1px) !important;
}

.react-multiple-carousel__arrow--right,
.react-multiple-carousel__arrow--left {
  top: 30%;
}
/* // banner slider custom button */
.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0 !important;
  margin-top: 0 !important;
  padding: 5px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  top: 50% !important;
  min-width: 43px !important;
  min-height: 43px !important;

  height: 43px !important;
  border-radius: 50% !important;
  background: rgba(58, 18, 18, 0.7) !important;
  opacity: 0.8 !important;
  z-index: 1 !important;
}
.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before,
.carousel .control-prev.control-arrow:before {
  margin: 0 !important;
  font-family: 'revicons' !important; 
}
.carousel .control-next.control-arrow:before {
  content: '\e825' !important;
  border: none !important;
}
.carousel .control-prev.control-arrow:before {
  content: '\e824' !important;
  border: none !important;
}

/* // navigation mobile menu */
ul.main-menu {
  display: inline-flex;
  list-style: none;
  z-index: 7;
}
.menu-item {
  list-style: none;
}
.menu-item a {
  position: relative;
  display: block;
  /* color: var(--mainWhite); */
  /* new changes */
  color: #5E25BC;
  color: var(--brandHover);
  text-decoration: none;
  cursor: pointer;
  list-style: none;
}

.sub__menus__arrows {
  position: relative;
}
.sub__menus__arrows svg,
.sub__menus__arrows__full svg {
  padding-top: 0px;
  position: absolute;
  top: 13px;
}
.sub__menus {
  position: absolute;
  display: none;
  background: white;
  border: 1px solid lavender;
  width: 227px;
  left: -17px;
  padding: 2px 0 0 0;
  z-index: 1000;
  box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
}
.languageSelectMobile {
  border-top: 1px solid #83BC25;
  border-top: 1px solid var(--brand);
  width: 90%;
  margin: 10px auto;
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 !important;
}
.action {
  display: flex;
}
.action > * + * {
  margin-left: 30px;
}

/* // carousel dots clor active */
/* clubs stufff start */
.react-multi-carousel-dot button {
  background: #000000 !important;
  background: var(--mainBlack) !important;
}
.react-multi-carousel-dot--active button {
  background: #83BC25 !important;
  background: var(--brand) !important;
}
.react-multi-carousel-list {
 overflow: visible !important;


}

  /* // dots show below ccarousel */
  .react-multi-carousel-dot-list{
    bottom: -30px !important;
  }

  /* // clubs stuff end */

  

@media (min-width: 992px) {
  .sub__menus__arrows:hover .sub__menus {
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus {
    display: block;
    z-index: 1000000;
  }
}
.menu-item .sub__menus a {
  padding: 7px 0 0px 0;
  margin: 7px 27px;
}
.menu-item:hover > a {
  color: #83BC25 !important;
  color: var(--brand) !important;
}
.menu-item .sub__menus li:hover a {
  color: #5E25BC !important;
  color: var(--brandHover) !important;
}
.navBarSiteLogoMobile {
  display: none;
}
.navBarSiteLogoMobile-lgScreen {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991px) {
  .navBarSiteLogo {
    /* display: none; */
  }
  .navBarSiteLogoMobile-lgScreen {
    /* display: none; */
  }
  .navBarSiteLogoMobile {
    display: flex;
    /* margin: 20px 0; */
    flex: 1 1;
    justify-content: center;
    align-items: center;
    /* new changes */
    justify-content: flex-end;
  }
  .navBarSiteLogoMobileFooter {
    margin: 0;
  }
  .action {
    flex: 1 1;
  }
  .action .navBarSiteLogoMobile {
    flex: 0 1;
  }
  .navBarSiteLogoMobile-img {
    margin-left: -15px;
    /* height: 38px; */
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 15rem;
  }
  .main-nav .menubar__button:hover {
    color: #83BC25;
    color: var(--brand);
  }
  .main-nav .menu-item:hover > a {
    color: #5E25BC !important;
    color: var(--brandHover) !important;
  }
  .main-nav .menubar__button {
    display: block !important;
    float: right;
    /* background-color: var(--brand); */
    /* color: #fff; */
    color: #5E25BC;
    color: var(--brandHover);
    padding: 6px 7px;
    border-radius: 5px;
    margin: auto 0;
    cursor: pointer;
    position: relative;
    z-index: 10037;
    /* new changes */
    /* color: var(--mainWhite); */

  }
  .main-nav .menubar__button svg {
    font-size: 27px;
  }
  .main-nav ul.menuq2 {
    display: flex !important;
    flex-direction: column;
    position: absolute;
    left: 0;
    /* top: 0; */
    top: 50px;
    bottom: 0;
    z-index: 10007;
    background-color: white;
    background-color: var(--mainWhite);
    border-bottom: 3px solid #110b09;
    border-bottom: 3px solid var(--card);
    /* right: 0; */
    padding-bottom: 17px;
    /* padding-top: 57px; */
    /* gap: 10px; */
    height: 50vh;
    width: 50vw;
  }

  .main-nav ul.main-menu {
    display: none;
  }
  .main-nav .sub__menus__arrows:hover .sub__menus {
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .sub__menus__Active {
    display: block !important;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .sub__menus__Active2 {
    display: block !important;
  }
  .menus__categorysss {
    display: none;
  }
  .menus__categorysss2 {
    display: none;
  }
  .menu-item .sub__menus__full a {
    text-align: left;
  }
  .ratingElement {
    width: 40px;
  }
  .action > * + * {
    margin-left: 15px;
  }
}


@media (max-width: 550px) {
  .ratingElement {
    width: 30px;
  }
  .carousel .slide {
    padding: 0;
  }
  .react-multiple-carousel__arrow--right {
    right: calc(6% + 1px) !important;
  }
  .react-multiple-carousel__arrow--left {
    left: calc(6% + 1px) !important;
  }
  .navBarSiteLogoMobile-img {
    margin-left: 0px;
    /* height: 30px; */
    min-width: 10rem;
    height: auto;
    width: 10rem;
  }

  .main-container {
    width: 94%;
   }
   


  /* // react date-picker  */

  .rdrDateRangePickerWrapper{
    flex-direction: column !important;
  }

  .main-nav ul.menuq2 {
    
    height: 45vh;
    width: 55vw;
  }


  /* // rmp size */


  .rmp-custom-overlay {
   
    font-size: 8px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    
}
.copy-popup {
   
    min-width: 95%;
    
  }
  


}




.selected {
  color: var(--brand) !important;
}

.navBarBTN:hover {
  text-decoration: underline;
  cursor: pointer;
}
.categories_info {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: none;
  font-size: var(--font16);
  background-color: var(--brand);
  padding: 14px 40px;
  border-radius: 50px;
}
.navBarSiteLogo {
  max-width: 15rem;
  /* max-width: 25rem; */
  height: auto;
  margin-left: 100px;
}

.navBarSiteLogo:hover {
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;

  background: var(--mainWhite);

  min-height: 100vh;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}
.container1 {
  /* display: grid;
    grid-template-rows: 1fr 2fr 1fr; */
}

.item {
  transition: all 0.3s ease-in-out;
}

.chosenCategoryGrid {
  flex-grow: 1;
  height: 100%;
}

.categoryItemTitle {
  background-color: #646464;
  color: white;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 17px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0.7rem 0.5rem 0.7rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  text-align: center;
  overflow: hidden;
  width: 15rem;
}

.categoryItemImg {
  object-fit: contain;
  display: block;
  max-height: 24rem;
  max-width: 16rem;
  /*min-height: 24rem;*/
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

/*
.categoryItem:hover {
  transform: scale(1.1);
}
*/

.detailsUpper {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-top: 25%;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
}

.detailsUpperBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.detailsUpperSmall {
  width: 80%;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.detailsUpperCover {
  width: 100%;
  vertical-align: middle;
}

.detailsPlayContainer {
  background-color: var(--brand);

  text-decoration: none;
  margin-bottom: 20px;
  color: var(--white);
  border: none;
  text-transform: uppercase;
  border-radius: var(--font12);
  line-height: 130%;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.detailsPlayContainer:hover .svg-play {
  fill: var(--brand);
}
.detailsPlayContainer:hover {
  cursor: pointer;
  background: var(--brandHover);
  color: var(--brand);
}

.svg-play {
  fill: white;
}

.detailsUpperCoverContainer {
  display: inline-block;
  width: 20%;
  position: absolute;
  left: 0;
  bottom: 0;
}

.categoryContent,
.similiarCategoryContent {
  margin: 0 auto;
  width: 95%;
  padding: 0 1rem 0 1rem;
}

.categoryContent::-webkit-scrollbar {
  display: none;
}

.banners {
  margin: 0 auto;
  width: 95%;
  padding: 0 1rem 0 1rem;
}

.catArrow {
  color: white;
  width: 2rem;
  position: relative;
  z-index: 100;
  align-self: center;
  cursor: pointer;
  font-size: 3rem;
  transition: all 0.3s ease-in-out;
}

.catArrow:hover {
  background-color: rgba(20, 20, 20, 5);
  box-shadow: 0px 0px 1rem #fff;
  border-radius: 1rem;
}

.arrowRight {
  margin-left: auto;
  margin-right: 1rem;
}

.arrowLeft {
  margin-left: 1rem;
}

.categoryTitleContainer,
.similiarCategoryTitleContainer {
  margin: 1.5rem 0 1.5rem 0;
  color: #fcca13;
  font-family: "Nunito", sans-serif;

  font-size: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.similiarContainer {
  margin-top: 1.5rem;
}

.categoryTitle {
  text-align: center;
  cursor: default;
}

.titleHover:hover {
  cursor: pointer;
  text-decoration: underline;
}

.faqContainer {
  margin: 0 auto 0 auto;
  width: 80%;
  color: white;
  font-size: 16px;
  flex-grow: 1;
}

.playVideoContainer {
  /*
 margin: 0 auto 0 auto;
  width: 100%;
  color: white;
  font-size: 16px;
  flex-grow: 1;
  */

  overflow: hidden;
  /*
  position: relative;
  padding-top: 56.25%;
  */
}

.maxContainerPrimary {
  width: 100%;
  background: black;
  position: relative;
  /* padding: 10px 0; */
}
.embedButton {
  position: absolute;
  /* // should be 40px all the time */
  bottom: 40px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--brand);
  /* padding: 0px 10px; */
  color: var(--silver);
  padding: 10px;
  opacity: 0;
}
.embedButton:hover {
  cursor: pointer;
}
.embedButton > * + * {
  margin-left: 10px;
}

.maxContainer {
  width: 70%;
  margin: 0 auto;
}
.maxContainerEmbed {
  width: 100%;
  margin: 0 auto;
  max-height: 100vh;
  /* overflow: hidden; */
}
.maxContainerPrimary:hover .embedButton {
  opacity: 1;
}

/*
.radiantPlayer {
  position: fixed;
  overflow: hidden;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000;
}

.radiantPlayer .rmp-control-bar {
  margin-top: -200px;
}
*/

.searchColumn {
  display: flex;
  flex-direction: column;
  margin-right: 2%;
}

.searchButton {
  height: 50px;
}

.searchField {
  position: relative;
  padding: 17px 20px 17px 15px;
  outline: none;
  color: var(--background);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  font-weight: 500;
  max-height: 50px;
  min-height: 50px;
  width: 400px;
}

.searchRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 1%;
  align-items: center;

  justify-content: space-between;
}

.searchCheckbox {
  margin-right: 0.25rem;
}

.searchCheckboxLabel {
  margin-right: 1%;
}

.resultsAmount {
  margin-left: 10%;
  margin-top: 1%;
  font-size: 16px;
  margin-right: 20%;
}

.searchResultBTN {
  background: var(--silver);
}

.active {
  background: var(--brand);
}
.searchResultBTN:hover {
  background: var(--brandHover);
}

.searchLabel {
  margin-bottom: 0.25rem;
  height: 18px;
}

.searchSelectCategory {
  padding: 0.25rem;
}

.searchContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: white;
  width: 80%;
  margin: 0 auto;
}

#searchForm {
  /* margin-left: 10%; */
}

.searchTitle {
  font-size: 28px;
  margin-bottom: 2rem;
}

.row {
  display: flex;
  flex-direction: row;
}

.slick-track {
  display: flex !important;
  grid-gap: 10px;
  gap: 10px;
}

.videoContainer {
  padding-top: 5%;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.details-description-container {
  width: 80%;
  margin: 0 auto;
  background-color: #1e2228;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 30px;
}

.detailsDescriptionTitleContainer {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #fcca13;
  padding: 10px;
  overflow: hidden;
}

.details-description-title-left {
  display: flex;
  flex-direction: column;
  width: 70%;
  float: left;
}

.details-description-title-right {
  max-width: 30%;
  margin-left: auto;
}

.details-description-title-name {
  font-size: 30px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  margin-right: auto;
}

.folderNamesList {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
}

.folderName {
  color: white;
  font-size: 20px;
  margin-right: 0.5rem;
}

.ratingElement {
  width: 50px;
}

.details-desription-mobile-cover {
  display: none;
}

.details-description-info-container {
  margin: 20px auto;
  display: flex;
}

.detailsContainer {
  font-family: "Nunito", sans-serif;
}

.detailsInfoContainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.infoDataContainer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  color: var(--silver);
}

.dataTitle {
  font-weight: bold;
  margin-right: auto;
}

.dataValue {
  margin-right: auto;
  padding: 10px 0px;
}

.shareBTN {
  background-repeat: no-repeat;
  background-size: cover;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.details-share {
  display: flex;
  padding-top: 35px;
  flex-wrap: wrap;
  width: 100%;
}

.detailsDescriptionContainer {
  flex: 3 1;
}

.svg-triangle {
  width: 26px;
  height: 20px;
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
}

.categoryGridContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.resultsGrid {
  width: 100%;
  margin-top: 1.5%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.categoryItem {
  /* max-width: 17rem; */
  transition: all 0.3s ease-in-out;
  margin: 0.5rem 0.25rem;
}

.promoContainer {
  width: 99%;
  margin: 0 auto;
  overflow: hidden;
}

.promoItem {
  box-sizing: border-box;
  width: 33.333%;
  padding: 0.2rem;
}

.promoItemImg {
  width: 100%;
  cursor: pointer;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.bannerItem {
  width: 100%;
  cursor: pointer;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.categoryItem:hover {
  cursor: pointer;
}

.footerLine {
  border-top: 2px solid #f6c917;
  margin: 20px 0;
}

.bottomBar {
  width: 100%;
  height: 120px;
  padding: 0 10% 1% 10%;
  display: table;
  color: white;
  z-index: 5;
}

.footerInfo {
  padding-left: 20px;
  display: inline-block;
}

.footerInfoName {
  font-size: 18px;
  margin-bottom: 15px;
}

.footerInfoAddress {
  font-size: 12px;
  float: left;
  margin-right: 180px;
}

.footerInfoPhoneEmail {
  display: inline-block;
  font-size: 12px;
}

.footerTos {
  font-size: 12px;
  float: right;
  padding-right: 20px;
}

.footerTos:hover {
  text-decoration: underline;
  cursor: pointer;
}

.tosContainer {
  margin: 0 auto 0 auto;
  width: 80%;
  flex-grow: 1;
  color: white;
  font-size: 12px;
}

.SVOD-container,
.TVOD-container {
  margin: 0.5rem;
  width: 10rem;
  height: 10rem;
  background-color: #fcca13;
  text-align: center;
}

.SVOD-container:hover,
.TVOD-container:hover {
  cursor: pointer;
}

.categoriesDropdown {
  flex-direction: column;
  position: absolute;
  background: var(--mainWhite) !important;

  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 1rem;
  z-index: 100000000000;
  width: 150px;

  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 0px;
  margin-top: 0px;
}
.categoriesDropdown > * + * {
  margin-top: 10px;
}
.categoriesDropdown a {
  text-decoration: none;
  color: var(--brand);
}
.categoriesDropdown a:hover {
  text-decoration: none;
  background-color: var(--mainBlack);
}
.categoriesDropdown li:hover {
  text-decoration: none;
  color: var(--mainWhite);
  background-color: var(--mainBlack);

  cursor: pointer;
}

.categoriesBar {
  display: flex;
  margin-top: 1rem;
  width: 93%;
  margin-left: auto;
  margin-right: auto;
}

.categoriesButtonContainer {
  display: inline-block;
  position: relative;
  color: white;
  font-size: 24px;
}

.categoriesButton {
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
}

.svg-chevron {
  margin-left: 0.5rem;
}

.dropdownOption {
  padding: 1rem;
  text-align: start;
  width: 100%;
  cursor: pointer;
}

.dropdownOption:hover {
  background: #313131;
}

.modal-background {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.5);
  cursor: pointer;
  position: absolute;
  z-index: 110;
}

.loginFormContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1.75rem auto;
  position: relative;
  background-color: #3d3d3d;
  border-radius: 0.25rem;
  padding: 1rem 2rem 1rem 2rem;
  max-width: 30rem;
  max-height: 20rem;
  z-index: 120;
  cursor: default;
}

#form_id {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.loginFormInput {
  width: 100%;
  margin: 0.25rem 0 0.25rem 0;
}

.signUpBarInput {
  padding-left: 0.5rem;
  width: 100%;
  margin-right: 0.25rem;
}

.signUpBar {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  color: white;
  align-items: center;
}

.signUpBar2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  color: white;
  align-items: center;
  padding: 20px auto;
}

.signUpBackground {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  height: 20rem;
  width: 100%;
  z-index: 1;
  padding: 0;
  margin: 0;
}

.signUpWelcomeText {
  font-size: 24px;
  width: 33.3%;
  z-index: 2;
  margin-top: 2rem;
}

.signUpWelcomeText2 {
  margin-left: auto;
  margin-right: auto;
  font-size: 48px;
  z-index: 2;
  margin-top: 2rem;
}

.signUpWelcomeText3 {
  font-size: 28px;
  z-index: 2;
  margin-top: 2rem;
}

.signUpRow {
  display: flex;
  flex-direction: row;
  margin-top: 1%;
  z-index: 2;
}

.loginMessage {
  height: 4rem;
}

.loginFormTOS {
  margin: 0.5rem 0 0.5rem 0;
  font-size: 18px;
  font-weight: normal;
}

.loginViewBackground {
  background-size: cover;
  display: flex;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  /*position: absolute;*/
  width: 100%;
  z-index: 1;
}

.loginViewContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.loginFormButtons {
  display: flex;
  flex-direction: row;
}

.loginFormBTN-background {
  background: #3d3d3d;
}

.loginFormBTN {
  background: lightgray;
  cursor: pointer;
  padding: 0.5rem;
  margin: 0 0.5rem 0 0;
  margin-left: 10%;
}

.loginFormBTN:hover {
  background: transparent;
}
/*
.signUpBarBTN-background {
  background: #3d3d3d;
}
*/

.signUpBarBTN {
  background: rgb(125, 221, 14);
  cursor: pointer;
  padding: 0.5rem;
  margin: 0 0.5rem 0 0;
  border-radius: 0.5rem;
  font-weight: 300;
  border: none;
  font-size: 38px;
}

.signUpBarInfoMessage {
  background: #f6c917;
  display: flex;
  font-size: 18px;
  z-index: 5;
  color: black;
  margin-top: 0.25rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.signUpBarBTN:hover {
  background: rgb(230, 229, 229);
  /* background: transparent;*/
}

.signUpFormBTN {
  background: lightgray;
  cursor: pointer;
  padding: 0.5rem;
  width: 80%;
  margin-left: 10%;
}

.signUpFormBTN:hover {
  background: rgb(230, 229, 229);
  /* background: transparent;*/
}

.signUpForm {
  display: flex;
  flex-direction: column;
  width: 25%;
  color: white;
}

.stepsContainer {
  font-size: 20px;
}

.signUpFormInput {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.signUpStep1 {
  display: flex;
  flex-direction: column;
}

.signUpStepText {
  font-size: 20px;
  margin-bottom: 0.5rem;
  width: 80%;
  margin-left: 10%;
}

.signUpStepTitle {
  font-size: 22px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.loginFormTitle {
  font-size: 22px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

#userDetails-form {
  display: flex;
  flex-direction: column;
}

.profile-container {
  width: 80%;
  flex-grow: 1;
  padding-top: 5%;
  margin: auto;
  display: flex;
  flex-direction: row;
  color: white;
}

.profile-menu-option {
  font-size: 18px;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
}

.profile-menu-option:hover {
  cursor: pointer;
  box-shadow: 0 0 11px rgba(241, 241, 241, 0.2);
}

.profile-option-view {
  color: black;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.profile-details-row {
  display: flex;
  align-items: center;
  margin: 0.5rem auto 0.5rem auto;
}

.inputColumn {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.25rem;
}

.profileFormInput {
  width: 30rem;
  box-sizing: border-box;
}

.profileFormBTN {
  align-self: center;
  width: 5rem;
  margin-left: 10%;
}

.loginFirstMessage {
  font-size: 18px;
  margin: auto;
}

.profileMessage {
  margin: 2rem auto;
}

.series-seasons-container {
  box-sizing: border-box;
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}

.series-seasons-header {
  font-family: "Nunito", sans-serif;

  color: white;
  padding: 20px 0;
}

.series-seasons-numbers {
  display: flex;
}
.series-seasons-numbers > a {
  margin-right: 5px;
}

.seasonNumber {
  background-color: var(--white);
  margin-right: 5px;
  color: var(--card);
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.seasonNumber:hover {
  background-color: var(--brandHover);
}

.series-episode-list {
  width: 80%;
  margin: 0 auto;
}

.episodeDetailsMiddle {
  width: 67%;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.episodeDetailsMiddleTop {
  display: flex;
  flex-direction: row;
}

.episodeDetailsMiddleBottom {
  margin-top: 1rem;
}

.episodeDetailsLeft {
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 100px;
  width: 175px;
}

.episodeDetailsRight {
  display: flex;
  width: 11%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.episodeDetailsItem {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  background: var(--card);
  color: var(--silver);
  border-radius: 0.25rem;
  height: 120px;
  min-height: 100px;
  max-height: 100px;
}

.info-duration {
  margin-left: 20px;
}

.episode-list-img {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  height: 100px;
  width: 175px;
}

.episode-list-img:hover {
  cursor: pointer;
}

.icon-play {
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;

  -webkit-transform: translate(-50%, -50%);

          transform: translate(-50%, -50%);
}
.icon-play:hover {
  color: var(--brandHover);
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;

  -webkit-transform: translate(-50%, -50%);

          transform: translate(-50%, -50%);
}

.episode-list-description {
  color: white;
  font-family: "Nunito", sans-serif;

  font-size: 16px;
}

.thumbnail-container {
  position: relative;
  box-sizing: border-box;
  height: 100px;
  width: 175px;
}

.informPlaceholder {
  font-size: 28px;
  color: white;
  text-align: center;
  height: 70%;
}

.langFlag {
  margin-right: 0.5rem;
  width: 40px;
  max-width: 40px;
  cursor: pointer;
}

.languageSelect {
  margin-left: auto;
  padding-top: 10px;
}
.categoriesContainer {
  padding-bottom: 50px;
  /* width: 95%; */
  margin: 0 auto;
}
.bannerContainer {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 0px;
}
@media (max-width: 991px) {
  .categories_info {
    padding: 0;
    background-color: var(--mainWhite) !important;
  }
  .categoriesDropdown {
    width: auto;
  }
  .categoriesDropdown a:hover {
    text-decoration: none;
    background-color: var(--mainWhite);
  }
  .categoriesDropdown li:hover {
    text-decoration: none;
    color: var(--mainWhite);
    background-color: var(--mainBlack);

    cursor: pointer;
  }
}
@media (max-width: 750px) {
  .bannerContainer {
    padding-bottom: 0px !important;
  }
  .categoriesContainer {
    max-width: 95%;
    margin: 0 5% 0 0;
  }
}
@media (max-width: 550px) {
  .navBarSiteLogo {
    max-width: 20rem;
  }
}

.Footer_Footer__1744h {
  display: flex;
  width: 88%;
  margin: 0 auto;
  line-height: 20px;
  padding: 50px 0px 50px 0px;
  /* color: var(--mainWhite) !important; */
  color: var(--brand);
}

.Footer_Footer_Description_Heading__3s8OY,
.Footer_Footer_Menu_Heading__1wpaz,
.Footer_Footer_SocialMedia_Heading__o2gwI {
  height: 52px;
  letter-spacing: 0.5px;
  font-weight: 500;
  color: var(--brand);


}

.Footer_Footer_Menu_Heading__1wpaz,
.Footer_Footer_SocialMedia_Heading__o2gwI {
  display: flex;
  align-items: center;
}
.Footer_Footer_Menu__118iQ a,
.Footer_Footer_SocialMedia__2lLpU a {
  color: var(--mainWhite);
  text-decoration: none;

}
.Footer_Footer_Menu__118iQ a:hover,
.Footer_Footer_SocialMedia__2lLpU a:hover {
  color: var(--brand);
  text-decoration: none;
  transition: 0.1s ease;
}
.Footer_Footer_Menu__118iQ,
.Footer_Footer_SocialMedia__2lLpU {
  flex: 1 1;
}

.Footer_Footer_SocialMedia_Heading_Info__3nh-K,
.Footer_Footer_Menu_info__2Ab78 {
  display: flex;
  flex-direction: column;
  letter-spacing: 1px;
  color: var(--mainWhite);
  /* gap: 10px; */
  align-items: flex-start;
  justify-content: center;
  color: var(--mainWhite);


}
.Footer_Footer_SocialMedia_Heading_Info__3nh-K > * + * {
  margin-top: 10px !important;
}
.Footer_Footer_Menu_info__2Ab78 > * + * {
  margin-top: 10px !important;
}

.Footer_Footer_Description__2q8pc {
  color: var(--mainWhite);


  flex: 3 1;

}
.Footer_Footer_Description__2q8pc >*+*{
  margin-top: 20px;
}
.Footer_Footer_Description_Heading_Info__1WZsu {
  padding: 10px 50px 10px 0;
}
.Footer_Footer_SocialMedia_Links__2T5BQ {
  display: flex;
  /* gap: 20px; */
  justify-content: center;
  align-items: center;
  color: var(--mainWhite);

}

.Footer_Footer_SocialMedia_Links__2T5BQ > * + * {
  margin-left: 10px;
}
.Footer_Footer_SocialMedia_Links_icons__28sKp {
  color: var(--mainWhite);

}
/* media Queeries */
@media (max-width: 1200px) {
  .Footer_Footer__1744h {
    width: 100%;
    padding: 0 20px 20px 10px;
  }
}
@media (max-width: 600px) {
  .Footer_Footer__1744h {
    flex-direction: column;
    /* gap: 30px; */
    width: 95%;
    margin-top: 50px;
    /* padding-bottom: 150px !important; */
  }
  .Footer_Footer_Description_Heading_Info__1WZsu {
    padding: 10px 10px 10px 0;
  }
  .Footer_Footer_SocialMedia_Heading_Info__3nh-K,
  .Footer_Footer_Menu_info__2Ab78 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    /* gap: 0px; */
  }
  .Footer_Footer_SocialMedia_Heading_Info__3nh-K > * + * {
    margin-top: 0px !important;
    margin-left: 10px !important;
  }
  .Footer_Footer_Menu_info__2Ab78 > * + * {
    margin-top: 0px !important;
    margin-left: 10px !important;
  }
}


.ItemImage_ViewedSectionPrimary__16dX8 {
    overflow: hidden;
    background-color: var(--mainWhite);
    color: var(--mainBlack);
}
.ItemImage_ViewedSectionSecondary__1fO2u {
    width: 80%;
    margin: auto;
}

.ItemImage_ViewedSectionSecondary__1fO2u h2 {
    text-align: center;
    padding-top: 40px;
    font-weight: 400;
}
.ItemImage_ViewedSectionSecondary__1fO2u h2:hover {
    color: var(--mainYellow);
}
.ItemImage_ViewedSectionSecondary__1fO2u h2:hover::after {
    background-color: var(--mainBlack);
}

.ItemImage_categoryList__3AM3n {
    width: 50%;
    margin: auto;
    padding-bottom: 30px;
}

.ItemImage_ViewedSectionSecondary__1fO2u .ItemImage_categoryList__3AM3n ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
}

.ItemImage_categoryList__3AM3n ul li {
    color: #999999;
    font-size: 0.75rem;
    padding-top: 12px;
}
.ItemImage_ViewedSectionSecondary__3videos__3VvDp {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 30px;
    grid-gap: 25px;
    gap: 25px;
}

.ItemImage_ViewedSectionSecondary__3videos__image__9T61V,
.ItemImage_ViewedSectionSecondary__2videos__image__1htTM {
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
}
.ItemImage_ViewedSectionSecondary__3videos__image__9T61V img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
}

.ItemImage_ViewedSectionSecondary__3videos__image_overlay__14JnO {
    position: absolute;
    bottom: 20px;
    color: var(--mainWhite);
    width: 100%;
}
.ItemImage_wrapper_bottom__1qmdc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.ItemImage_wrapper_bottom_likes__2guzn,
.ItemImage_wrapper_bottom_duration__324fY {
    background-color: var(--mainBlack);
    color: var(--mainWhite);
    padding: 5px;
    font-size: 0.75rem;
    font-weight: 800;
}

.ItemImage_wrapper_bottom_likes__2guzn {
    margin-left: 10px;
}
.ItemImage_wrapper_bottom_duration__324fY {
    margin-right: 10px;
}
.ItemImage_ViewedSectionSecondary__2videos__32RZ6 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
    gap: 25px;
    padding-bottom: 30px;
    margin-bottom: 30px;
}
.ItemImage_secondaryClassName__3Jlay {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
}

.ItemImage_ViewedSectionSecondary__3videos__main__1Kj7o img,
.ItemImage_ViewedSectionSecondary__2videos__main__20uAU img {
    transition: 0.6s ease;
    width: 100%;
}
.ItemImage_ViewedSectionSecondary__3videos__image_Overlay__1-N4C {
    position: absolute;
    background: var(--cardbackground);
    color: var(--white);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 0.5s;
    border-radius: 16px;
}
.ItemImage_ViewedSectionSecondary__3videos__image_Overlay_blur__2FA8L {
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
}

/* // animation of play button */
.ItemImage_ViewedSectionSecondary__3videos__image_Overlay__1-N4C > * {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    transition: -webkit-transform 0.25s;
    transition: transform 0.25s;
    transition: transform 0.25s, -webkit-transform 0.25s;
}
.ItemImage_ViewedSectionSecondary__3videos__image_Overlay__1-N4C:hover > * {
    -webkit-transform: translateY(0);
            transform: translateY(0);
}
.ItemImage_ViewedSectionSecondary__3videos__image_Overlay__1-N4C:hover {
    opacity: 1;
}
.ItemImage_ViewedSectionSecondary__3videos__videoicon_overlay__2wBoc {
    cursor: pointer;
    z-index: 2000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.ItemImage_ViewedSectionSecondary__3videos__videoicon_overlay__2wBoc > * + * {
    margin-top: 20px;
}
.ItemImage_ViewedSectionSecondary__3videos__videoicon_overlay_view_all__3eEkW {
    background: var(--brand);
    color: var(--brandHover);
}
.ItemImage_ViewedSectionSecondary__3videos__videoicon_overlay_view_all__3eEkW:hover {
    background: var(--brandHover);
    color: var(--brand);
}

.ItemImage_ViewedSectionSecondary__2videos__main__20uAU img {
    /* height: 350px;
    min-height: 350px;
    max-height: 350px; */
}
.ItemImage_ViewedSectionSecondary__2videos__main__20uAU:hover img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.ItemImage_ViewedSectionSecondary__2videos__main__20uAU:hover img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.ItemImage_ViewedSectionSecondary__2videos__image__1htTM:hover,
.ItemImage_ViewedSectionSecondary__3videos__image__9T61V:hover {
    cursor: pointer;
}
.ItemImage_ViewedSectionSecondary__3videos__text_description__1spCt {
    display: flex;
    flex-direction: column;

    grid-gap: 4px;

    gap: 4px;
}
.ItemImage_ViewedSectionSecondary__3videos__text_description_heading__2RFvh {
    font-weight: 500;
    color: var(--white);
    line-height: 20.8px;
}
.ItemImage_ViewedSectionSecondary__3videos__text_description_duration__2IGvx {
    display: flex;
    font-weight: 400;
    color: var(--silver);
    grid-gap: 10px;
    gap: 10px;
    line-height: 16.8px;
}
.ItemImage_ViewedSectionSecondary__3videos__text_description__1spCt:hover {
    /* color: var(--mainYellow); */
    cursor: pointer;
}

.ItemImage_ViewedSectionSecondary__3videos__text_actions__3iKKA {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    font-size: 0.875rem;
    color: #999999;
}
.ItemImage_ViewedSectionSecondary__3videos__text_actions__3iKKA p {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.ItemImage_ViewedSectionSecondary__3videos__text_description_status__J3iUC {
    color: var(--brand);
    line-height: 15.4px;
    font-weight: 400;
    font-size: var(--font11);
}
.ItemImage_ViewedSectionSecondary__3videos__text_description_status__J3iUC:hover {
    color: var(--brandHover);
}

/* media Queeries */
@media (max-width: 750px) {
    .ItemImage_ViewedSectionSecondary__1fO2u {
        width: 100%;
        margin: 0 10px 0 10px;
    }
}
@media (max-width: 550px) {
}

.ItemTitle1_divider__6Z_q2 {
  margin-right: 5px;
  margin-left: 5px;
}

.ItemTitle1_ViewedSectionPrimary__1g-O2 {
  overflow: hidden;
  background-color: var(--mainWhite);
  color: var(--mainBlack);
}
.ItemTitle1_ViewedSectionSecondary__1wEfP {
  width: 80%;
  margin: auto;
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description_heading_desc__235qj {
  color: #ffffff;
  line-height: 140%;
}
.ItemTitle1_ViewedSectionSecondary__1wEfP h2 {
  text-align: center;
  padding-top: 40px;
}
.ItemTitle1_ViewedSectionSecondary__1wEfP h2:hover {
  color: var(--mainYellow);
}
.ItemTitle1_ViewedSectionSecondary__1wEfP h2:hover::after {
  background-color: var(--mainBlack);
}

.ItemTitle1_categoryList__2qoAP {
  width: 50%;
  margin: auto;
  padding-bottom: 30px;
}

.ItemTitle1_ViewedSectionSecondary__1wEfP .ItemTitle1_categoryList__2qoAP ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.ItemTitle1_categoryList__2qoAP ul li {
  color: #999999;
  padding-top: 12px;
}
.ItemTitle1_ViewedSectionSecondary__3videos__zcnnJ {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 30px;
  grid-gap: 25px;
  gap: 25px;
}
.ItemTitle1_ViewedSectionSecondary__3videos__image__ZaRBA,
.ItemTitle1_ViewedSectionSecondary__2videos__image__133nr {
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}
.ItemTitle1_ViewedSectionSecondary__3videos__image__ZaRBA img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  /* min-height: 190px; */
}
.ItemTitle1_ViewedSectionSecondary__3videos__image_overlay__2dfgK {
  position: absolute;
  bottom: 20px;
  color: var(--mainWhite);
  width: 100%;
}
.ItemTitle1_wrapper_bottom__Ok69i {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ItemTitle1_wrapper_bottom_likes__sOgzz,
.ItemTitle1_wrapper_bottom_duration__45zjU {
  background-color: var(--mainBlack);
  color: var(--mainWhite);
  padding: 5px;
  font-weight: 800;
}

.ItemTitle1_wrapper_bottom_likes__sOgzz {
  margin-left: 10px;
}
.ItemTitle1_wrapper_bottom_duration__45zjU {
  margin-right: 10px;
}
.ItemTitle1_ViewedSectionSecondary__2videos__3-191 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  gap: 25px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.ItemTitle1_secondaryClassName__2NhmY {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
}

.ItemTitle1_ViewedSectionSecondary__3videos__main__5f4gx img,
.ItemTitle1_ViewedSectionSecondary__2videos__main__1KMNy img {
  transition: 0.6s ease;
  width: 100%;
}
.ItemTitle1_ViewedSectionSecondary__3videos__videoicon_overlay__c8B6p {
  position: absolute;
  opacity: 0;
  color: var(--mainWhite);

  /* bring your own prefixes */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.699);
  padding: 15px;
  border: 2px solid transparent;
  border-radius: 50%;
}
.ItemTitle1_ViewedSectionSecondary__2videos__image__133nr:hover
  .ItemTitle1_ViewedSectionSecondary__3videos__videoicon_overlay__c8B6p {
  opacity: 1;
}
.ItemTitle1_ViewedSectionSecondary__3videos__image__ZaRBA:hover
  .ItemTitle1_ViewedSectionSecondary__3videos__videoicon_overlay__c8B6p {
  opacity: 1;
}
.ItemTitle1_ViewedSectionSecondary__2videos__main__1KMNy img {
}
.ItemTitle1_ViewedSectionSecondary__2videos__main__1KMNy:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.ItemTitle1_ViewedSectionSecondary__2videos__main__1KMNy:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.ItemTitle1_ViewedSectionSecondary__2videos__image__133nr:hover,
.ItemTitle1_ViewedSectionSecondary__3videos__image__ZaRBA:hover {
  cursor: pointer;
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description__oaCJV {
  display: flex;
  flex-direction: column;
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description__oaCJV > * + * {
  margin-top: 5px;
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description_heading__1GeMu {
  font-weight: 500;
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description_duration__1kJ8z {
  display: flex;
  font-weight: 400;
  color: var(--brandHover);
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description_duration__1kJ8z > * + * {
  margin-right: 5px;
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description__oaCJV:hover {
  cursor: pointer;
}

.ItemTitle1_ViewedSectionSecondary__3videos__text_actions__FSMWe {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  color: #999999;
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_actions__FSMWe p {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description_heading__1GeMu {
  /* color: var(--mainWhite); */
}

.ItemTitle1_ViewedSectionSecondary__3videos__text_description_status__3ATwD {
  color: var(--brand);
}
.ItemTitle1_ViewedSectionSecondary__3videos__text_description_status__3ATwD:hover {
  /* color: var(--brandHover); */
}

/* media Queeries */
@media (max-width: 750px) {
  .ItemTitle1_ViewedSectionSecondary__1wEfP {
    width: 100%;
    margin: 0 10px 0 10px;
  }
}
@media (max-width: 550px) {
}

.ChosenCategoryGrid_chosenCategoryGrid__kcP3Z {
  width: 80%;
  margin: 0 auto;
}

.ChosenCategoryGrid_ViewedSectionSecondary__3videos__33HlR {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 350px));
  align-items: center;
  justify-content: center;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  grid-row-gap: 20px;
  row-gap: 20px;
}

/* media Queeries */
@media (max-width: 950px) {
  .ChosenCategoryGrid_chosenCategoryGrid__kcP3Z {
    width: 100%;
    margin: 0 10px;
  }
}
@media (max-width: 750px) {
  .ChosenCategoryGrid_ViewedSectionSecondary__3videos__33HlR {
    grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 350px));
  }
}
@media (max-width: 550px) {
  .ChosenCategoryGrid_chosenCategoryGrid__kcP3Z {
    width: 80%;
    margin: 0 auto;
  }
  .ChosenCategoryGrid_ViewedSectionSecondary__3videos__33HlR {
    grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
  }
}

.CategoryTitle2_CategoryItem_main__Wb_bA {
    /* margin: 30px auto; */
    margin-bottom: 0px;
    /* width: 90%; */
}
.CategoryTitle2_CategoryItem_main_info__1vXwJ {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    justify-content: space-between;
    width: 95%;
}

.CategoryTitle2_CategoryItem_main_link__7uylU {
    height: 100%;
    border: none;
    color: var(--brandHover);
    text-decoration: none;
    font-weight: 400;
    line-height: 140%;
    transition: 0.3s ease;
}

.CategoryTitle2_CategoryItem_main_link__7uylU:hover {
    cursor: pointer;
    color: var(--brand);
}

.CategoryTitle2_CategoryItem_main_info_title__2uoEa {
    color: var(--mainBlack);
    line-height: 28px;
    margin: 0px 10px;
}

.CategoryTitle2_CategoryItem_main_info_title__2uoEa,
.CategoryTitle2_CategoryItem_main_info__1vXwJ button {
    padding: 30px 0px;
}

.Header_HeaderTitle__2WbA4,
.Header_HeaderTitleAdmin__A62sJ {
    height: 100%;
    border: none;
    color: var(--brand);
    text-decoration: none;
    font-weight: 400;
    line-height: 140%;
    transition: 0.3s ease;
    padding: 10px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Header_HeaderTitleAdmin__A62sJ {
    color: var(--brand) !important;
}

.Header_searchContainer_back_icon__2RGAI {
    display: flex;
    align-items: center;
    color: var(--mainCategoryViewAll) !important;
    /* margin-left: -200px; */
}
.Header_searchContainer_back_icon__2RGAI:hover {
    cursor: pointer;
    color: var(--brand) !important;
}
.Header_searchContainer_back__1UpZ3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Header_FaIcon__1E9-R {
    margin-right: 12px;
}

.Header_FaIconSearch__2zjhx {
    color: var(--brandHover);
}

.Header_FaIconSearch__2zjhx:hover {
    cursor: pointer;
    color: var(--brand);
}

/* // search stufff */
#Header_searchForm__2h1PA {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}
.Header_searchRow__X6ATC {
    margin-bottom: 1%;
    display: flex;
    height: 50px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.Header_searchColumn__2Vt5N {
    display: flex;
    /* flex-direction: column; */
    min-height: 50px;
    max-height: 50px;
    height: 50px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: var(--mainWhite);
    width: 400px;
    outline: 1px solid var(--brand);
    max-width: 90%;
    border-radius: 30px;
}

.Header_searchField__1Rr8a {
    padding: 18px 0;
    min-height: 50px;
    max-height: 50px;
    height: 50px;
    text-align: center;
    /*border-radius: 2px;*/
    flex: 4 1;
    border: none;
    outline: none;
    color: var(--brand);
}

/* Placeholder color */
::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--brand);
}
::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--brand);
}
:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--brand);
}
:-moz-placeholder {
    /* Firefox 18- */
    color: var(--brand);
}

@media (max-width: 550px) {
    .Header_HeaderTitle__2WbA4 {
        padding: 10px 0px;
        flex-direction: column;
        grid-gap: 20px;
        gap: 20px;
    }
    .Header_HeaderTitleAdmin__A62sJ {
        flex-direction: row;
    }

    .Header_HeaderTitle__2WbA4 > * + * {
        /* margin-top: 20px; */
    }

    #Header_searchForm__2h1PA {
        width: 95%;
        margin: 0 auto;
    }
}

/* html {
    font-size: 62.5%;
  }
   */
.Input1_input_text__151zP {
  font-family: inherit;

  /* padding: 1.5rem 2.5rem 0.5rem 1rem; */
  padding: 0.9rem;
  /* border: none; */
  border: 1px solid var(--brand);
  border-radius: 1rem;
  background: var(--mainWhite);
  width: 100%;
  -webkit-appearance: none;
          appearance: none;
  height: 50px;
}
.Input1_input_text__151zP:focus {
  outline-color: var(--brand);
}

.Input1_inp__1NJUl {
  position: relative;
}
.Input1_inp__1NJUl input {
  padding: 1.5rem 2.5rem 0.5rem 1rem !important;
}
.Input1_select_label__YeCCE {
  position: relative;
}
.Input1_select_label__YeCCE::after {
  content: "";
  position: absolute;
  z-index: 20;
  right: 12px;
  top: 65%;
  margin-top: -3px;
  height: 0px;
  width: 0px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  pointer-events: none;
  border-top: 6px solid rgb(33, 37, 96);
}

.Input1_label__2PDmm {
  pointer-events: none;

  position: absolute;
  top: 1.3rem;
  left: 1rem;
  /* color: #00000070; */
  color: var(--brand);
  font-weight: 500;

  transition: all 0.2s;
  -webkit-transform-origin: left;
          transform-origin: left;
  top: 50%;
  bottom: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Input1_input_text__151zP:not(:-ms-input-placeholder) + .Input1_label__2PDmm {
  top: 0.3rem;
  transform: scale(0.8);
}
.Input1_input_text__151zP:not(:placeholder-shown) + .Input1_label__2PDmm,
.Input1_input_text__151zP:focus + .Input1_label__2PDmm {
  top: 0.3rem;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}
.Input1_input_text__151zP:focus + .Input1_label__2PDmm {
  color: var(--brand);
}

.Input1_input_icon__3htxW {
  position: absolute;
  top: 1.3rem;
  right: 2rem;
  color: #00000070;
}

.Input1_input_icon_change__1Ov3l {
  position: absolute;
  top: 0.7rem;
  /* right: 2rem; */
  right: 0;
  color: #00000070;
  cursor: pointer;
  top: 0.3rem;
}

.Input1_formPrimary__oTPT0 {
  background-color: white;
  width: 100%;
  /* height: 100%; */
  min-height: 100%;
  width: 80%;
  margin-top: 50px;
}
.Input1_formScoreboard__DKk2q {
  /* width: 100%; */
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}
.Input1_formPrimarySignIn__3W0H6 {
  max-height: 600px;
  margin: 50px auto;
}
.Input1_formPrimary1__oRpGC {
  background-color: var(--card);
  width: 100%;
  /* height: 100%; */
  /* min-height: 100%; */
  width: 60%;
  margin: 0 auto;
  /* margin-top: 50px; */
}
.Input1_formPrimary2__kRKDS {
  min-height: 100% !important;
  height: 100%;
  max-height: 100%;
}

/* // for image and form to align flex */
.Input1_formSecondary__3NqMm {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  min-height: 100%;
}

/* // image  */
.Input1_formSecondary__3NqMm img {
  height: 60vh;
  width: 40vw;
  display: flex;
}
.Input1_formBlogAdmin__We1_1 {
  width: 100%;
  height: auto;

  padding-bottom: 50px;

  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  background-color: var(--mainWhite);
  margin: 0 20% 0 50px;
}
.Input1_FormAdmin__3FOgr {
  background-color: var(--mainWhite);
  padding: 0;
}
.Input1_formBlog__YBRZ- {
  width: 50%;
  height: auto;
  margin: 0 20% 0 20%;
  /* border: 1px solid var(--card);
    background-color: var(--card); */
  padding: 0 20px 20px 20px;
  height: auto;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  min-height: 100%;
  /* margin: 50px 0; */
  margin: 0 auto;
}
.Input1_formBlogAdmin2__TEDbs {
  background-color: #dfdfdf;
  border-radius: 10px;
}

/* // h2 */
.Input1_formHeader__20onU {
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
}
.Input1_formHeader__20onU h2 {
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
}
/* Guest: Ανδρέας Χαραλάμπους, Ιδρυτής της Capsule Skateboards */
.Input1_formHeader__20onU::after {
  background-color: var(--brand);
}
/* // invalid form input */
.Input1_formInputInvalid__27T2x input {
  outline-color: var(--red);
  border: 2px solid var(--red) !important;
}
/* // error text */
.Input1_errorText__1X7JD {
  color: var(--red);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -15px;
}
.Input1_errorText__1X7JD::before {
  /* display: inline; */
  content: "⚠  \00a0  ";
}

.Input1_loginRow__mCe0x {
  flex-direction: column;
  color: var(--mainBlack);
}
.Input1_loginRowAdmin__vWSLW {
  flex-direction: column;
  color: var(--brandHover);
}
.Input1_loginRow__mCe0x > * + * {
  margin-top: 10px;
}
.Input1_loginRow__mCe0x a {
  text-decoration: none;
  color: var(--brand);
  border-bottom: 1px solid transparent;
}
.Input1_loginRow__mCe0x a:hover {
  border-bottom: 1px solid var(--brand);
}
@media (max-width: 950px) {
  .Input1_formScoreboard__DKk2q {
    flex-direction: column;
  }
}
@media (max-width: 550px) {
  .Input1_input_text__151zP {
    height: 40px;
  }
  .Input1_input_text__151zP {
    padding: 0.5rem 1rem;
  }

  .Input1_input_icon_change__1Ov3l {
    top: 0;
  }
  .Input1_formBlogAdmin__We1_1 {
    padding: 0;
    padding-bottom: 50px;
    padding: 2.5%;
  }
  .Input1_label__2PDmm {
    /* top: 0.8rem; */
  }
  .Input1_formBlog__YBRZ- {
    width: 100%;
    margin: 0 auto;
  }
  .Input1_formPrimary__oTPT0 {
    width: 100%;

    margin-top: 0;
  }
  .Input1_formPrimary1__oRpGC {
    width: 100%;
  }
  .Input1_formBlogAdmin2__TEDbs {
    border-radius: 0;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px var(--mainWhite) inset; /* Change the color to your own background color */
  -webkit-text-fill-color: var(--mainWhite);
}

.button {
     
color: white;
cursor: pointer;
text-align: center;

 padding: 10px 56px;
 text-decoration: none;
 border: none;
  font-size: var(--font16);
  border-radius: 12px;
  background-color:var( --brand);
  }
  
  .button:focus {
    outline: none;
  }
  
  .button:hover,
  .button:active {
  background-color:var( --brandHover);


  }
  
  .button--inverse {
    background: var(--mainWhite);
    color: var(--brand);
    border: 1px solid var(--brand);
  }
  
  .button--inverse:hover,
  .button--inverse:active {
    color: var(--mainWhite);
    border: 1px solid var(--mainWhite);
    background-color: var(--brand);

  }
  
  .button--danger {
    background: #830000;
    border-color: #830000;
  }
  
  .button--danger:hover,
  .button--danger:active {
    background: #f34343;
    border-color: #f34343;
  }
  
  .button:disabled,
  .button:hover:disabled,
  .button:active:disabled {
    background: #ccc;
    color: #979797;
    border-color: #ccc;
    cursor: not-allowed;
  }
  
  .button--form{
    height: 50px;
  }



  @media (max-width: 550px) {
    
    .button--form{
      height: 40px;
    }
  
  }
   





/* // for react duration css */
.rdt input{
  outline: none !important;
  border: none !important;
  padding: 0 !important;
}

 
.rdtOpen .rdtPicker {
  display: block;
  background:  var(--mainWhite)  !important;
  color: var(--brand) !important;
  border: none !important;
}

 
.button-switch {
    /* font-size: 1.5em; */
    height: 1.875em;
    margin-bottom: 0.625em;
    position: relative;
    width: 4.5em;
  }
  .button-switch .lbl-off,
  .button-switch .lbl-on {
    cursor: pointer;
    display: block;
    font-size: 0.9em;
    font-weight: bold;
    line-height: 1em;
    position: absolute;
    top: 0.5em;
    transition: opacity 0.25s ease-out 0.1s;
    text-transform: uppercase;
  }
  .button-switch .lbl-off {
    right: 0.4375em;
  }
  .button-switch .lbl-on {
    color: #fefefe;
    opacity: 0;
    left: 0.4375em;
  }
  .button-switch .switch {
    -webkit-appearance: none;
    appearance: none;
    height: 0;
    font-size: 1em;
    left: 0;
    line-height: 0;
    outline: none;
    position: absolute;
    top: 0;
    width: 0;
  }
  .button-switch .switch:before, .button-switch .switch:after {
    content: "";
    font-size: 1em;
    position: absolute;
  }
  .button-switch .switch:before {
    border-radius: 1.25em;
    background: #bdc3c7;
    height: 1.875em;
    left: -0.25em;
    top: -0.1875em;
    transition: background-color 0.25s ease-out 0.1s;
    width: 4.5em;
  }
  .button-switch .switch:after {
    box-shadow: 0 0.0625em 0.375em 0 #666;
    border-radius: 50%;
    background: #fefefe;
    height: 1.5em;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    transition: -webkit-transform 0.25s ease-out 0.1s;
    transition: transform 0.25s ease-out 0.1s;
    transition: transform 0.25s ease-out 0.1s, -webkit-transform 0.25s ease-out 0.1s;
    width: 1.5em;
  }
  .button-switch .switch:checked:after {
    -webkit-transform: translate(2.5em, 0);
            transform: translate(2.5em, 0);
  }
  .button-switch .switch:checked ~ .lbl-off {
    opacity: 0;
  }
  .button-switch .switch:checked ~ .lbl-on {
    opacity: 1;
  }
  .button-switch .switch:checked:before {
    background: var(--brand);
  }
  
.DetaillsFolderNames_folderName__IjA5N {
  margin-right: 0.5rem;
  background: var(--darkGray);
  color: var(--blueSilver);
  border-radius: 5px;
  padding: 10px 10px;

  border-radius: 100px;
}
.DetaillsFolderNames_folderName__IjA5N:hover {
  background: var(--brandHover);
  cursor: pointer;
  color: var(--brand);
}

.RenderItemDescription_itemDescription__2c6Nl {
  width: 100%;
  box-sizing: border-box;
  float: left;
  /* padding: 0 15% 0 1%; */
  color: var(--silver);
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 22.4px;
  line-height: 1.62857;
}

@media (max-width: 550px) {
  .RenderItemDescription_itemDescription__2c6Nl {
    line-height: 19.6px;
    line-height: 1.62857;
  }
}

.ContactUs_ContactUs__c-F_T {
  background-color: #ddd;
  width: 100%;
  margin: 0 auto;
  letter-spacing: 0.3px;
  line-height: 140%;
  word-spacing: 1px;
}
.ContactUs_ContactUsPrimary__2NpHG {
  display: flex;
  width: 80%;
  height: 100vh;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
}
.ContactUs_ContactUsPrimary__2NpHG > * + * {
  margin-left: 50px;
}
.ContactUs_ContactUs_image__JgdyD {
  flex: 0.8 1;

  display: flex;
}
.ContactUs_ContactUs_image__JgdyD img {
  height: 100%;
  width: 100%;
}
.ContactUs_ContactUs_description__3JviG {
  flex: 1 1;
  color: var(--mainBlack);
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  flex-direction: column;
}
.ContactUs_ContactUs_description_Primary__QJkt- {
  width: 80%;
  margin: 0 auto;
}
.ContactUs_ContactUs_description__3JviG h4 {
  padding: 0 10px;
  color: var(--brand);
}
.ContactUs_ContactUs_description_Primary__QJkt- > * + * {
  margin-top: 50px;
}

.ContactUs_ContactUs_description_email_button__1V0nn {
  background-color: var(--brand);
  text-decoration: none;
  color: var(--white);
  padding: 10px 56px;
  border: none;
  font-size: var(--font16);
  border-radius: 12px;
}
.ContactUs_ContactUs_description_email_button__1V0nn:hover {
  background-color: var(--brand);
}
.ContactUs_ContactUs_description_address_info__3oB50,
.ContactUs_ContactUs_description_phone__1wLqB,
.ContactUs_ContactUs_description_email__3Hp9D {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 10px;
}
.ContactUs_ContactUs_description_address_info__3oB50 > * + * {
  margin-top: 5px;
}
.ContactUs_ContactUs_description_address_info_title__1BlTu {
  margin-bottom: 10px !important;
  color: var(--brand);
}
/* media Queeries */
@media (max-width: 991px) {
  .ContactUs_ContactUs_image__JgdyD {
    display: none;
  }
}

.Events_container_2videos__1E4lz {
  color: var(--mainWhite);
  margin: 0 auto;
  overflow: hidden;
}
 

 

 
 
.Events_Events_main_info__Eu18s {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
}



.Events_Events_main_info__Eu18s h2 {
  padding: 10px 30px;
}

.Events_Events_main__3GBUi h2::after {
  display: none;
}
.Events_container_3videos__AUTnF {
  color: var(--mainWhite);
  margin: 0 auto;
  overflow: hidden;
}
.Events_container_homePage__11NB- {
  width: 95%;
}
.Events_EventTitle__AA4re {
  color: var(--itemTitleEvent);
  /* font-family: 'Russo One', sans-serif; */
}

.Events_Events__1G-ma {
  background-color: var(--card );
  border-radius: 10px;
}
.Events_Events__1G-ma:hover {
  cursor: pointer;
}
.Events_Event__1s27a {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: var(--brand);
  padding-bottom: 20px;
  height: 200px;
  max-height: 200px;
  min-height: 200px;
}
.Events_EventTitle__AA4re {
  padding-bottom: 10px;
}

.Events_EventInfo__3hpQ7 {
  color: var(--mainYellow);
  padding-bottom: 20px;
  /* font-weight: 700; */
}
.Events_EventDuration__1dDPn {
  margin-top: 10px;
  /* font-weight: 700; */
}
.Events_EventInfo__3hpQ7,
.Events_EventTitle__AA4re,
.Events_EventDuration__1dDPn {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
}

.Events_EventInfo__3hpQ7 > * + * {
  margin-left: 10px !important;
}
.Events_EventTitle__AA4re > * + * {
  margin-left: 10px !important;
}
.Events_EventDuration__1dDPn > * + * {
  margin-left: 10px !important;
}
.Events_EventTitle__AA4re:hover {
  text-decoration: underline;
  color: var(--brand);
}
.Events_icon__1fDAN {
}

.Events_event__2YtBx {
  width: 80%;
  margin: 0 auto;
  padding-top: 30px;
}
.Events_event__2YtBx h2 {
  color: var(--mainWhite);
  padding-bottom: 0;
}
.Events_iframe__2O-cM {
  /* height: 80vh; */
  border: none;
  margin: 10px 0;
}

.Events_ViewedSectionSecondary__2videos__3DHdJ {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 300px));
  align-items: center;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  grid-row-gap: 20px;
  row-gap: 20px;
}
.Events_ViewedSectionSecondary__3videos__3WbiD {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 350px));
  align-items: center;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  grid-row-gap: 20px;
  row-gap: 20px;
  
}
.Events_NoDataAvailable__3Q7MW {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  padding: 10px 0 30px 0;
  /* background: whitesmoke; */
}

.Events_NoDataAvailable__3Q7MW h3 {
  color: var(--brand);
}
.Events_NoDataAvailable__3Q7MW img {
  width: 400px;
}

.Events_modalItem__3W5V_ {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  justify-content: center;
  align-items: center;
  background-color: var(--mainWhite);
}
.Events_modalItem_button__2sTw2 {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 20px;
}
.Events_modalItem_button__2sTw2 button {
}
.Events_modalItem_button_confirm__lnht- {
  background-color: var(--card);
  color: var(--mainWhite);
}
.Events_modalItem_button_confirm__lnht-:hover {
  background-color: var(--brand);
  color: var(--mainWhite);
}
.Events_modalItem_button_cancel__3pT7E:hover {
  background-color: var(--red);
  color: var(--mainWhite);
}
.Events_Events_main_info_filter_addFilter__2ueil,
.Events_Events_main_info_filter_removeFilter__21mUA {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.Events_Events_main_info_filter_addFilter__2ueil {
  background-color: var(--card);
  color: var(--mainWhite);
}
.Events_Events_main_info_filter_addFilter__2ueil:hover .Events_filterIcon__3THkZ {
  background-color: var(--mainYellow);
  color: var(--mainWhite);
}
.Events_Events_main_info_filter_addFilter__2ueil:hover {
  background-color: var(--mainCategoryViewAllHover);

  color: var(--mainWhite);
}
.Events_Events_main_info_filter_removeFilter__21mUA:hover .Events_removeIcon__17aE5 {
  background-color: var(--red);
  color: var(--mainWhite);
}
.Events_Events_main_info_filter_removeFilter__21mUA:hover {
  background-color: var(--red);
  color: var(--mainWhite);
}
/* filter icon */
.Events_filterIcon__3THkZ {
  color: var(--mainYellow);
}
.Events_filterIcon__3THkZ:hover {
  color: var(--mainWhite);
  cursor: pointer;
}
.Events_removeIcon__17aE5 {
  color: var(--red);
}

.Events_Events_main_info_button__1-pBM {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  color: var(--card);
}
.Events_Events_main_info_header__1BGv9 {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1050px) {
  .Events_container_2videos__1E4lz {
    width: 100%;
  }
}
@media (max-width: 850px) {
  .Events_ViewedSectionSecondary__2videos__3DHdJ {
    grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 300px));
  }
  .Events_container_2videos__1E4lz {
    width: 100%;
  } 
  .Events_Events_main_info__Eu18s h2 {
    padding: 20px 10px;
  }
  .Events_NoDataAvailable__3Q7MW img {
    width: 300px;
  }
}
@media (max-width: 650px) {
  .Events_container_2videos__1E4lz {
    width: 88%;
    margin: 0 auto;
  }
  .Events_NoDataAvailable__3Q7MW img {
    width: 200px;
  }
}


@media (max-width:550px) {
  
  .Events_Events_main_info_filter_addFilter__2ueil,
.Events_Events_main_info_filter_removeFilter__21mUA {
  padding: 10px 10px;
}

.Events_modalItem__3W5V_ {
 width: 100% ;
 height: 100%;
}

}
.EventsItem1_Events_main_info__3Jxdv {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
}
.EventsItem1_eventsItem1__2KRzP {
  color: var(--brand);
}
.EventsItem1_eventsItem1_hideCarousel__2khKP {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 275px));
  align-items: center;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.EventsItem1_eventsItem1_primary__1a2OM,
.EventsItem1_eventsItem1_card__2i3gl,
.EventsItem1_eventsItem1_image__2lpwF,
.EventsItem1_eventsItem1_secondary__2Jm7m,
.EventsItem1_eventsItem1_heading__3Ouit,
.EventsItem1_eventsItem1_card_admin__qbsMM,
.EventsItem1_eventsItem1_primary_admin__3dwDi {
  display: flex;
  justify-content: center;
  align-items: center;
}

.EventsItem1_eventsItem1_card__2i3gl {
  min-height: 200px;
  border-radius: 50px;
  flex-direction: column;

  background-color: var(--card);
}
.EventsItem1_eventsItem1_text__1MyC- {
  text-align: center;
}

.EventsItem1_eventsItem1_primary__1a2OM {
  display: flex;
  flex: 2 1;
  border-radius: 50px;
  background-color: var(--mainWhite);
  width: 100%;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  min-height: 150px;
}

.EventsItem1_eventsItem1_secondary__2Jm7m {
  flex: 1 1;
}

.EventsItem1_eventsItem1_image__2lpwF {
  width: 100%;
  justify-content: space-evenly;
}
.EventsItem1_eventsItem1_image__2lpwF img {
  width: 90px;
  max-height: 80px;
}

.EventsItem1_eventsItem1_heading__3Ouit {
  grid-gap: 10px;
  gap: 10px;
}

/* admin stuff */
.EventsItem1_eventsItem2__awQQx {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 200px));

  align-items: center;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  grid-row-gap: 20px;
  row-gap: 20px;
}
.EventsItem1_eventsItem1_card_admin__qbsMM {
  border-radius: 20px;
  min-height: 200px;
  flex-direction: column;

  background-color: var(--card);
  border: 1px solid var(--card);
}

.EventsItem1_eventsItem1_primary_admin__3dwDi {
  display: flex;
  flex: 2 1;
  border-radius: 20px;
  background-color: var(--mainWhite);
  width: 100%;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.EventsItem1_eventsItem1_primary_admin__3dwDi:hover,
.EventsItem1_eventsItem1_card__2i3gl:hover {
  cursor: pointer;
}

.EventsItem1_NoDataAvailable__13KVe {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.EventsItem1_NoDataAvailable__13KVe img {
  height: 200px;
}
@media (max-width: 990px) {
  .EventsItem1_eventsItem1_hideCarousel__2khKP {
    justify-content: center;
  }
}

@media (max-width: 550px) {
  /* admin stuff here only 1  item per rowc */
  .EventsItem1_eventsItem2__awQQx {
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 300px));
  }
}

 
 /* link which is inside ItemLink */
.ItemLink_ItemLink__1w02_ a {
    height: 100%;
    border: none;
    color: var(--mainBlack);
    text-decoration: none;
    /* font-weight: 400; */
    line-height: 140%;
    transition: 0.3s ease;
    margin-right: 5px;
    /* font-weight: 800; */
  }
  .ItemLink_ItemLink__1w02_ a:hover {
    transition: all ease 0.4s;
    cursor: pointer;
    color: var(--brand);
  
  }
 /* which does not include a href */


  .ItemLink_ItemLink__1w02_  {
    height: 100%;
    border: none;
    color: var(--mainCategoryViewAll);
    text-decoration: none;
    font-weight: 400;
    line-height: 140%;
    transition: 0.3s ease;
    margin-right: 5px;
    font-weight: 800;
  }
  .ItemLink_ItemLink__1w02_:hover {
    transition: all ease 0.4s;
    cursor: pointer;
    color: var(--mainCategoryViewAllHover);
  
  }
.RadiantPlayer_RadiantPlayer__3iTPu {
  /* position: fixed;
  overflow: hidden;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000; */
  width: 100%;
  /* padding: 20px 0; */
  /* margin: 0 auto; */
}
.RadiantPlayer_RadiantPlayer__3iTPu .RadiantPlayer_rmp-control-bar__2AqcS {
  /* margin-top: -200px; */
}

.UpcomingEvent_EventPrimary__fGcNZ {
    width: 100%;
    margin: 30px auto 0 auto;
    background-color: var(--mainWhite);
    color: var(--silver);
    /* margin-top: 50px; */
    padding: 30px;
}
.UpcomingEvent_EventSecondary__3LY4h {
    width: 88%;
    margin: 0 auto;
}
.UpcomingEvent_SecondaryContainer__3sQfS {
    background-color: black;
    width: 88%;
    margin: 0 auto;
    padding: 20px 10px;
}
.UpcomingEvent_EventTertairy__2zGMq {
    width: 88%;

    margin: 0 auto;
}
.UpcomingEvent_EventPrimary__fGcNZ > * {
    padding-bottom: 10px;
}

.UpcomingEvent_EventPrimary_title__cFiC_ {
    padding: 50px 0 20px 0;
    color: var(--itemTitle);
}
h1::after,
h2::after {
    display: none;
}
.UpcomingEvent_EventPrimary_countdown__2f_FJ {
    /* font-size: 2.5rem; */
}
.UpcomingEvent_EventPrimary_countdown__2f_FJ {
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
}
.UpcomingEvent_EventPrimary_countdown__2f_FJ > * + * {
    margin-left: 20px;
}

.UpcomingEvent_EventPrimary_countdown__2f_FJ span {
    padding: 20px;
    background-color: var(--card);
    color: var(--mainWhite);
}
.UpcomingEvent_EventPrimary_countdown_description__9qBaX {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.UpcomingEvent_EventPrimary_countdown_description__9qBaX > * + * {
    margin-top: 10px;
}

.UpcomingEvent_EventPrimary_countdown_description__9qBaX p,
.UpcomingEvent_EventPrimary_date__3VZFF,
.UpcomingEvent_EventPrimary_time__2Y9SJ {
    /* font-size: 1.3rem; */
}

.UpcomingEvent_EventPrimary_date__3VZFF,
.UpcomingEvent_EventPrimary_time__2Y9SJ {
    display: flex;
    /* margin-top: 10px; */
    align-items: center;
}
.UpcomingEvent_EventPrimary_date__3VZFF > * + * {
    margin-left: 20px;
}
.UpcomingEvent_EventPrimary_time__2Y9SJ > * + * {
    margin-left: 20px;
}
.UpcomingEvent_EventTimeDescription__3GpLq > * + * {
    margin-top: 20px;
}
.UpcomingEvent_EventTimeDescription__3GpLq {
    margin-top: 60px;
    margin-bottom: 50px;
    color: var(--brand);
}

.UpcomingEvent_EventPrimary_description__3z1Ob > * {
    margin: 30px 0;
    line-height: 200%;
}
.UpcomingEvent_EventPrimary_description__3z1Ob {
    line-height: 200%;
    word-spacing: 3px;
    letter-spacing: 0.5px;
}
.UpcomingEvent_EventPrimary_description__3z1Ob span,
.UpcomingEvent_EventPrimary_description__3z1Ob p,
.UpcomingEvent_EventPrimary_description__3z1Ob h1 h2 h3 {
    background-color: white !important;
    color: var(--mainBlack) !important;
}
.UpcomingEvent_EventPrimary_description__3z1Ob a {
    text-decoration: none;
    color: var(--brand);
}
.UpcomingEvent_EventPrimary_description__3z1Ob a:hover {
    text-decoration: underline;
    color: var(--brandHover);
}
.UpcomingEvent_EventPrimary_description__3z1Ob p {
    font-size: 1.1rem;
}

/* // ticket */
.UpcomingEvent_buyTicket__HzJ0n {
    display: flex;
    align-items: center;
    /* width: 88%; */
    /* margin: 0 auto; */
}
.UpcomingEvent_buyTicket__HzJ0n button {
    line-height: 150%;
    /* background-color: var(--brand); */
    /* color: var(--mainWhite); */
    /* height: 40px;
  max-height: 40px; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    min-width: 300px;
}
.UpcomingEvent_buyTickets_info_button__b6_Zp {
    display: flex;
    justify-content: center;
    align-self: flex-end;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}
.UpcomingEvent_buyTicket__HzJ0n > * + * {
    margin-left: 10px;
}
.UpcomingEvent_buyTickets_info_button__b6_Zp > * + * {
    /* margin-left: 10px; */
}
.UpcomingEvent_buyTickets_info_input_null__IMeQf {
    visibility: hidden;
    opacity: 0;
}
.UpcomingEvent_buyTickets_info__3zZh8 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.UpcomingEvent_buyTickets_info__3zZh8 > * + * {
    margin-top: 10px;
}
.UpcomingEvent_buyTickets_info_input__1TILO {
    display: flex;
    justify-content: center;
    align-items: center;
}
.UpcomingEvent_buyTickets_info_input__1TILO > * + * {
    margin-left: 10px;
}
.UpcomingEvent_buyTickets_info_input__1TILO input {
    /* height: 40px; */
    padding: 12px 12px;
    width: 250px;
    /* max-height: 40px; */
    height: 50px;
}
.UpcomingEvent_buyTickets_info_btn__1-FiR {
    background-color: var(--mainBlack) !important;
}
@media (max-width: 1140px) {
    .UpcomingEvent_SecondaryContainer__3sQfS {
        background-color: black;
        width: 100%;
        margin: 0 auto;
        padding: 0;
    }
}

@media (max-width: 550px) {
    .UpcomingEvent_EventTertairy__2zGMq {
        width: 100%;

        margin: 0 auto;
        padding: 0 20px;
    }
    .UpcomingEvent_buyTickets_info_button__b6_Zp {
        flex-direction: column;
        width: 100%;
    }
    .UpcomingEvent_EventPrimary__fGcNZ {
        padding: 10px 0;
    }
    /* // ticket */
    .UpcomingEvent_buyTicket__HzJ0n {
        flex-direction: column;
    }
    .UpcomingEvent_buyTickets_info_button__b6_Zp > * + * {
        margin-left: 0;
        margin-top: 10px;
    }
    .UpcomingEvent_buyTicket__HzJ0n > * + * {
        margin-top: 20px;
    }
    .UpcomingEvent_buyTickets_info_input__1TILO {
        width: 100%;
    }
    .UpcomingEvent_buyTickets_info_input__1TILO input {
        width: 100%;
    }
    .UpcomingEvent_buyTickets_info__3zZh8 {
        width: 100%;
    }
}

.RenderBanner_primarycontainer__2JZPN {
  background-color: hsla(0, 2%, 9%, 0.64);
}
.RenderBanner_primarycontainer_Promo__14XWW {
  padding: 10px 0 50px 0;
}
.RenderBanner_secondaryContainer__1CfdD {
  width: 80vw;
  margin: auto;
  color: var(--white);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  gap: 10px;
}
/* // aspect-ratio  */

.RenderBanner_container_singleItemPrimaryBanner1__3qt8u {
  position: relative;
  padding-bottom: 56.25%;
}
.RenderBanner_container_singleItemPrimaryBanner2__S5SuX {
  position: relative;
}
.RenderBanner_container_singleItem_image__1D4dp {
  height: 100%;
  width: 100%;
  opacity: 0.7;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  position: absolute;
}

.RenderBanner_container_singleItem__1DWkd {
  position: relative;
  color: var(--white);
  cursor: pointer;
}

.RenderBanner_wrapper_center__2mQ2B {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 12%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-around;
  /* width: 100%; */
  padding: 30px 10px;
}

.RenderBanner_wrapper_center_info__5lJE9 {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  font-weight: 500;
  align-items: flex-start;
  color: var(--red);
}
.RenderBanner_wrapper_center_info_category__3aWkn,
.RenderBanner_wrapper_center_info_duration__RI18J {
  opacity: 0.9;
  grid-gap: 3px;
  gap: 3px;
  display: flex;
}

.RenderBanner_wrapper_center_info_category__3aWkn {
}
.RenderBanner_wrapper_center_info_title__2A8FG {
  font-size: var(--font36);
  font-weight: 800;
  opacity: 1 !important;
  margin-bottom: 20px;
}
.RenderBanner_wrapper_center__2mQ2B button {
  background-color: var(--brand);
  color: var(--white);
  border: none;
  text-transform: uppercase;
  border-radius: var(--font12);
  line-height: 130%;

  /* font-size: inherit; */
}
.RenderBanner_wrapper_center__2mQ2B button:hover {
  cursor: pointer;
  background-color: var(--brandHover);
  /* color: var(--brand); */
  transition: 0.3s ease;
}
.RenderBanner_wrapper_center__2mQ2B button:focus {
  cursor: pointer;
  color: var(--brandPressed);
  transition: 0.3s ease;
}
/* .wrapper_center button:hover {
  } */
.RenderBanner_wrapper_bottom__23s5h {
  position: absolute;
  bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 800;
}

.RenderBanner_wrapper_bottom_likes__RsF12 {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  margin-left: 10px;
}
.RenderBanner_wrapper_bottom_duration__1sW5x {
  margin-right: 10px;
}
.RenderBanner_posterImage__2lelQ {
  height: 80px;
}

.RenderBanner_container_multipleItem__2IZ1u {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
  align-items: center;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.RenderBanner_container_multipleItemprimary__ICG5G {
  /* overflow: hidden; */
  position: relative;
  min-width: 250px;
  min-height: 250px;
}
.RenderBanner_container_multipleItemprimary__ICG5G:hover {
  cursor: pointer;
}
.RenderBanner_container_multipleItem__text__24-bt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  padding: 5px 0;
}
.RenderBanner_container_multipleItem__text__24-bt .RenderBanner_post_cateogory__1KgWK {
  color: var(--mainYellow);
}
.RenderBanner_container_multipleItem__text__24-bt .RenderBanner_post_title__1MglO {
}

.RenderBanner_container_multipleItem__image__2M3k8 {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.RenderBanner_post_title__1MglO:hover {
  color: var(--mainYellow);
  transition: 0.3s ease;
}

.RenderBanner_container_multipleItem__image__2M3k8 img {
  width: 100%;
}

.RenderBanner_editorChoice__3P7mP {
  position: absolute;
  top: 0;
}
.RenderBanner_editorChoice__3P7mP img {
  width: 40px;
}
.RenderBanner_bannerTitle_title__oxulX {
  position: absolute;
  bottom: 50px;
  padding: 50;
  margin-bottom: 0;
  background: var(--mainWhite);
  color: var(--mainBlack);
  padding: 10px 60px;
  font-weight: 800;
}
.RenderBanner_bannerTitle__3B256 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  background: var(--mainWhite);
}

/* media Queeries */
@media (max-width: 750px) {
  .RenderBanner_secondaryContainer__1CfdD {
    width: 100vw;
    margin: 0 10px 0 10px;
  }
  .RenderBanner_posterImage__2lelQ {
    height: 100px;
  }
  .RenderBanner_wrapper_center_info_title__2A8FG {
  }

  .RenderBanner_editorChoice__3P7mP img {
    width: 30px;
  }
  .RenderBanner_wrapper_center__2mQ2B {
    left: 10%;

    padding: 20px 0px;
  }
}
@media (max-width: 650px) {
  .RenderBanner_wrapper_center__2mQ2B button {
    display: none;
  }

  /* font-size: inherit; */
  .RenderBanner_wrapper_center__2mQ2B {
    top: 40%;

    padding: 20px 0px;
  }

  .RenderBanner_wrapper_center_info__5lJE9 {
    width: 90%;
  }
}
@media (max-width: 550px) {
  .RenderBanner_container_multipleItem__2IZ1u {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 1fr));
    align-items: center;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    grid-row-gap: 20px;
    row-gap: 20px;
  }
  .RenderBanner_editorChoice__3P7mP img {
    width: 20px;
  }

  .RenderBanner_secondaryContainer__1CfdD {
    /* width: 90vw; */
  }
  .RenderBanner_wrapper_center__2mQ2B {
    left: 5%;
  }

  .RenderBanner_posterImage__2lelQ {
    height: 70px;
  }
}

/* Height */

@media (max-height: 850px) {
  .RenderBanner_wrapper_center__2mQ2B {
    margin-top: 0vh;
  }
}
@media (max-height: 700px) {
  .RenderBanner_wrapper_center__2mQ2B {
    grid-gap: 20px;
    gap: 20px;
  }
}

@media (max-height: 550px) {
  .RenderBanner_wrapper_center__2mQ2B {
    grid-gap: 0px;
    gap: 0px;
  }
}

.Banner_bannerContainer__31F6I {
  /* width: 90%;
  margin: 0 auto; */
}

.wrapper-demo {
  flex: 1 1;
  width: 400px;

  z-index: 200;
  /* background: var(--brand); */
}

/****-----start-wrapper-dropdown-2----****/
.wrapper-dropdown-2 {
  position: relative;
  padding: 17px 20px 17px 15px;
  cursor: pointer;
  outline: none;
  color: var(--brandHover);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  font-weight: 600;
  max-height: 50px;
  min-height: 50px;
}
.dropdown:before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  right: 20px;
  top: -3%;
  margin-top: -6px;
  border-width: 0px 12px 12px 12px;
  border-style: solid;
  border-color: var(--brandHover) var(--background);
}

.wrapper-dropdown-2 .dropdown {
  /* Size & position */
  position: absolute;
  top: 136%;
  left: 0px;
  right: 0px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  /* Styles */
  background: var(--mainBlack);
  transition: all 0.3s ease-out;
  list-style: none;
  z-index: 1;

  /* Hiding */
  opacity: 0;
  pointer-events: none;
}
.wrapper-dropdown-2 .dropdown li:last-child {
  border-bottom: none;
}
.wrapper-dropdown-2 .dropdown li {
  transition: all 0.3s ease-out;
}
.wrapper-dropdown-2 .dropdown li div {
  display: block;
  text-decoration: none;
  color: var(--mainWhite);
  padding: 15px;
  transition: all 0.3s ease-out;
  text-transform: capitalize;
  font-weight: 600;
}
.dropdown li div:hover {
  /* color: var(--brand); */
}
.dropdown li div:hover .icon {
  background-position: 0px 0px;
}
/* Hover state */
.wrapper-dropdown-2 .dropdown li:hover {
  background: var(--brandHover);
}
.wrapper-dropdown-2 .dropdown li:nth-child(1) {
  border-top-left-radius: 5px;
  -webkit-border-top-left-radius: 5px;
  -moz-border-top-left-radius: 5px;
  -o-border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-top-right-radius: 5px;
  -o-border-top-right-radius: 5px;
}
.wrapper-dropdown-2 .dropdown li:last-child {
  border-bottom-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
  -o-border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-bottom-right-radius: 5px;
  -o-border-bottom-right-radius: 5px;
}
.wrapper-dropdown-2 .dropdown li:nth-child(2),
.wrapper-dropdown-2 .dropdown li:nth-child(3),
.wrapper-dropdown-2 .dropdown li:nth-child(4) {
  border-radius: none;
}
/* Active state */
.wrapper-dropdown-2.active .dropdown {
  opacity: 1;
  pointer-events: auto;
  max-height: 25rem;
  overflow: scroll;
}
.active {
  background: var(--brandHover) !important;
  color: var(--mainWhite);
}

.wrapper {
  margin: 20px auto;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  /* VIA CSS MATIC https://goo.gl/cIbnS */
  box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.15);
  overflow-y: auto;
}

.info {
  display: flex;
  align-items: center;
}

.StateView_stateViewContainer__3I9AW {
  width: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-between;
}

.StateView_stateView__R78qc {
  border-bottom: 2px solid var(--mainBlack);
  color: var(--mainBlack);
  text-align: center;
  width: calc(33% - 1em);
  font-size: 1.5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.StateView_stateView__R78qc:not(:last-child)::before {
  display: block;
  content: ' \003E';
  
  height: 1em;
  width: 1em;
  line-height: 1em;
  border-radius: 100%;
  margin-bottom: 0.25rem;
  text-align: center;
  z-index: 1;
  font-size: 18px;
  border: 4px solid;
  padding: 4px;
  position: absolute;
  bottom: -20px;
  /* right: -30px; */
  right: calc(33% - (33% + 2em));
  color: var(--mainActive);
}
.StateView_stateViewSelected__18XcE:not(:last-child)::before {
  color: var(--card);
}

.StateView_stateView__R78qc:hover {
  cursor: pointer;
}

.StateView_stateViewSelected__18XcE {
  border-bottom: 2px solid var(--card);
  color: var(--card);
}




@media (max-width: 550px) {
  .StateView_stateViewContainer__3I9AW {
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
     
  }
  .StateView_stateViewContainer__3I9AW>  *+*{
    
  margin-top: 50px;
     
  }
  .StateView_stateView__R78qc {
    width: 100%;
    border-bottom: 0;
    justify-content: flex-start;
  }

  .StateView_stateView__R78qc:not(:last-child)::before {
   top: 40px;
   bottom: 0;
   right: 0;
   left: 0;
   content: ' \25be';
   
  }
  
  
  
   
}



.PackageComponent_packageComponent__15Hpt {
  width: 28%;
  min-width: 300px;
  border: 2px solid var(--brand);
  border-radius: 4px;
  /* margin: 0px 2% 2%; */
  background-color: var(--mainWhite);
  min-height: 670px;
  padding: 20px 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-height: 670px;
}

.PackageComponent_packageTitle__ybtPr {
  color: var(--brand);
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.PackageComponent_packagePrice__pskma {
  color: var(--brand);
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  border-bottom: 1px solid var(--mainActive);
  margin-bottom: 20px;
  padding: 35px 0;
  word-wrap: break-word;
}

.PackageComponent_packageDescription__3Sp-S {
  text-align: center;
  /* font-size: 1rem; */
  color: var(--mainBlack);
  /* height: 200px; */
  min-height: 300px;
  height: 300px;
  overflow: scroll;
}

.PackageComponent_packageBuyButtonContainer__15Usn {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  /* position: absolute; */
  bottom: 0;
  /* left: 50%; */
  /* transform: translate(-50%,-50%); */
  justify-content: center;
  /* right: 50%; */
  /* left: 50%; */
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  left: 20%;
  padding: 0;
  bottom: 10px;

}

.PackageComponent_packageBuyButton__1hjhi {
  border: 1px solid var(--brand);
  background-color: var(--brand);
  color: var(--brandHover);
}

.PackageComponent_packageBuyButton__1hjhi:hover {
  color: var(--brand);
  background-color: var(--brandHover);
  border: 1px solid var(--brandHover);
}

.PackageComponent_packageBoughtButton__2Bp-p {
  border: 1px solid var(--brand);
  background-color: var(--brand);
  color: var(--brandHover);
  text-align: center;
  padding: 3px 30px;
  font-size: 1rem;
  border-radius: 4px;
  display: inline-block;
}

.PackageComponent_popupEmailForm__2z5Gt {
  width: 100%;
}
.PackageComponent_popupEmailForm__2z5Gt input {
  padding: 11px 10px;
  border: none;
  /* background: #12161d; */
  color: var(--mainBlack);
  /* font-family: 'Gotham Greek Light', sans-serif !important; */
  /* // safari support */

  -webkit-appearance: initial;
}
.PackageComponent_emailConfirmModalButton__3WQCQ{

  display: flex;
  align-items: center;
}

.PackageComponent_emailConfirmModalButton__3WQCQ *+*{
  margin-left: 10px;
}

.PackageComponent_divariTeamSelect__KiDP0 input{
    height: 40px !important;
}
.PackageComponent_divariTeamSelect__KiDP0 input::-webkit-input-placeholder{
    color: var(--brand) !important;
}
.PackageComponent_divariTeamSelect__KiDP0 input:-ms-input-placeholder{
    color: var(--brand) !important;
}
.PackageComponent_divariTeamSelect__KiDP0 input::placeholder{
    color: var(--brand) !important;
}
.PackagesView_packagesView__9yr1V {
}

.PackagesView_packages__-3W1Y {
  width: 88%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 300px));
  grid-gap: 30px;
  gap: 30px;
  align-items: center;
  margin-bottom: 150px;
}

.PackagesView_packageHelp__1iO3u {
  width: 88%;
  margin: 0 auto;
}
.PackagesView_profilePageLink__b6Q-o{
    color: var(--brandHover);
    text-decoration: none;

}
.PackagesView_profilePageLink__b6Q-o:hover{
    color: var(--brand);
    text-decoration: none;
    
}
.PackagesView_packageHelpTitle__MBEaA {
  color: var(--mainBlack);
  font-size: 1.5rem;
  /* width: 88%; */
  margin: 0 auto;
  text-align: left;
}

.PackagesView_packageHelpText__1uwLf {
  color: var(--mainBlack);
  /* width: 88%; */
  margin: 50px auto;

  padding: 30px 30px;
  background-color: whitesmoke;
}
.PackagesView_packagesView__9yr1V input {
  height: 50px;
}

.PackagesView_packageHelp_input__2HVHf {
  display: flex;
  width: 60%;
  margin: 20px 40% 0 0;
}

.PackagesView_packageHelp_input__2HVHf input {
  font-size: larger;
  color: white;
  background-color: var(--card);
  border: none;
  padding-left: 10px;
}

@media (max-width: 550px) {
  .PackagesView_packageHelp_input__2HVHf {
    display: flex;
    width: 100%;
    margin: 20px 0 0 0;
    flex-direction: column;
  }
  .PackagesView_packages__-3W1Y {
    justify-content: center;
  }
}

/* for the background color of the form and image */
.Input_iconColor__394WK {
  width: 100%;
  height: 100%;
  font-size: large;
  color: var(--white);
}
.Input_formPrimary__WAqML {
  background-color: var(--background);
  width: 100%;
  /* height: 100vh; */
}

.Input_loginRow__1rq0K {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  color: var(--white);
}
.Input_loginRow__1rq0K a {
  text-decoration: none;
  color: var(--brand);
}
.Input_loginRow__1rq0K a:hover {
  color: var(--brandHover);
}
.Input_signUpConditionsRow__MSj9o {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Input_signUpConditionsRow__MSj9o a {
  color: var(--brand);
  text-decoration: none;
  padding: 10px;
}
.Input_signUpConditionsRow__MSj9o a:hover {
  color: var(--brandHover);
  transition: all 0.5s ease-in-out;
  /* transform: scale(1.1); */
}
.Input_signUpFormContainer__3ywaa {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  align-items: center;
}
.Input_formSignUpPrimary__1bdNV {
  width: 50%;
}

/* // for image and form to align flex */
.Input_formSecondary__2qVoJ {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

/* // image  */
.Input_formSecondary__2qVoJ img {
  height: 60vh;
  width: 40vw;
  display: flex;
}
/* main form */

.Input_form__szJBJ {
  width: 70%;
  height: auto;
  margin: 10% 15%;
  border: 1px solid var(--white);
  background-color: var(--white);
  padding: 20px;
}

/* //form blog is for big text area input like creatign blogs */
.Input_formBlog__QnvY0 {
  width: 50%;
  height: auto;
  margin: 50px 20% 0 20%;
  border: 1px solid var(--card);
  background-color: var(--card);
  padding: 20px;
}
.Input_formBlogAdmin__3iA9W{
  width: 100%;
  height: auto;
  /* margin: 50px 0; */
  border: 1px solid var(--card);
  background-color: var(--card);
  padding:0 5%;

  background-color: gray;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
}

/* // input of form */
.Input_formSecondary__2qVoJ input,
.Input_formSecondary__2qVoJ textarea,
.Input_formSecondary__2qVoJ select {
  padding: 11px 10px;
  border: none;
  background-color: var(--white);
  color: var(--mainBlack);

  /* color: var(--white); */
  
  /* // safari support */

  /* -webkit-appearance:inherit; */
  /* appearance:inherit; */
}
.Input_formSecondary__2qVoJ input:disabled{
  cursor: not-allowed;
}
.Input_formSecondary__2qVoJ select {
  padding: 11px 10px !important;
  /* color: var(--mainBlack); */



}
 .Input_selectFirstSelectedValue__3c9-3{
   color: red !important;
 }

.Input_formSecondary__2qVoJ select::-webkit-input-placeholder, .Input_formSecondary__2qVoJ textarea::-webkit-input-placeholder, .Input_formSecondary__2qVoJ input::-webkit-input-placeholder {
  font-size: medium;
  font-size: initial;
  color: var(--brandHover) !important;
}

.Input_formSecondary__2qVoJ select:-ms-input-placeholder, .Input_formSecondary__2qVoJ textarea:-ms-input-placeholder, .Input_formSecondary__2qVoJ input:-ms-input-placeholder {
  font-size: medium;
  font-size: initial;
  color: var(--brandHover) !important;
}

.Input_formSecondary__2qVoJ select::placeholder, 
.Input_formSecondary__2qVoJ textarea::placeholder,
.Input_formSecondary__2qVoJ input::placeholder {
  font-size: medium;
  font-size: initial;
  color: var(--brandHover) !important;
}
/* // focus of input */
.Input_formSecondary__2qVoJ input:focus,
.Input_formSecondary__2qVoJ textarea:focus {
  outline: none;
}
/* // icon and input together */

.Input_formInput__2a6Jo {
  width: 80%;
  background: var(--blueSilver);
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  align-items: center;
  /* padding-left: 15px; */
  text-align: center;
  border: 1px solid var(--cardStroke);
  border-radius: 4px;
  margin: 20px auto;
}

.Input_profilePageInput__2EJiF {
  width: 80%;
  background: var(--blueSilver);
  display: grid;
  grid-template-columns: 0.1fr 1fr;
  align-items: center;
  /* padding-left: 15px; */
  text-align: center;
  border: 1px solid var(--cardStroke);
  border-radius: 4px;
  margin: 15px auto;
}

/* // invalid form input */
.Input_formInputInvalid__3trEQ {
  background-color: var(--red) !important;
  color: var(--white) !important;
  border: 1px solid var(--red) !important;
}
/* // focusing the whole icon and input when focused upon inpu */
.Input_formInput__2a6Jo:focus-within {
  background-color: var(--card);
  border: 1px solid var(--card);
}
.Input_formInput__2a6Jo {
  background-color: var(--card);
  border: 1px solid var(--card);
}

/* // h2 */
.Input_formHeader__2KD_k {
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
}
.Input_formHeader__2KD_k h2 {
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
}
/* Guest: Ανδρέας Χαραλάμπους, Ιδρυτής της Capsule Skateboards */
.Input_formHeader__2KD_k::after {
  background-color: var(--brand);
}

/* // link textxx */
.Input_linkText__1_0_U {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 80%;
  margin-top: 20px;
}
/* // for image file button margin */
.Input_formInputImageUploadInput__25fhW {
  margin-left: 20px;
}

/* // error text */
.Input_errorText__15zRO {
  color: var(--red);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -15px;
}
.Input_errorText__15zRO::before {
  /* display: inline; */
  content: '⚠  \00a0  ';
}

.Input_loginFormButton__3yZgk {
  background: var(--brand);
  color: var(--white);
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Input_loginFormButton__3yZgk:hover {
  background: var(--brandHover);
  color: var(--brand);
}

@media (max-width: 799px) {
  .Input_form__szJBJ {
    width: 60%;
    height: auto;
    margin: 10% 20%;
    border: 1px solid var(--white);
    background-color: var(--white);
    padding: 20px;
  }
  .Input_formSecondary__2qVoJ img {
    display: none;
  }
  .Input_formBlog__QnvY0 {
    width: 80%;
    height: auto;
    margin: 50px 10% 0 10%;
  }
}
@media (max-width: 1025px) {
  .Input_formSecondary__2qVoJ img {
    width: 30%;
  }

  .Input_form__szJBJ {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 600px) {
  .Input_formSecondary__2qVoJ img {
    display: none;
  }
  .Input_form__szJBJ {
    border: 1px solid var(--white);
    background-color: var(--white);
    padding: 20px;
  }
  .Input_formInput__2a6Jo {
    width: 100%;
  }
  .Input_formBlog__QnvY0 {
    width: 90%;
    margin: 5%;
  }
  .Input_formSecondary__2qVoJ {
    margin: 0;
  }
}
@media (max-width: 470px) {
  .Input_form__szJBJ {
    border: 1px solid var(--white);
    background-color: var(--white);
    padding: 20px;
    margin: 0;
  }
  .Input_formBlog__QnvY0 {
    margin: 2.5%;
    width: 95%;
  }

  .Input_formInput__2a6Jo {
    width: 100%;
  }
  .Input_formPrimary__WAqML {
    height: 70vh;
    padding-bottom: 5rem;
  }
}

/* Change text in autofill textbox
input:-webkit-autofill {
  -webkit-text-fill-color: var(--white) !important;
  background-color: red !important;
  background: red !important;
} */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px var(--mainWhite) inset; /* Change the color to your own background color */
  -webkit-text-fill-color: var(--brand);
}

.PaymentMethod_paymentMethodContainer__TCANk {
  width: 100%;
}

.PaymentMethod_paymentMethodBuy__1d2A0 {
  cursor: pointer;
  text-align: center;
  font-size: 1.5rem;
  width: 100%;
}

.PaymentMethod_paymentMethodImage__2TCby {
  max-height: 80px;
  max-width: 100%;
}

.SelectedPackage_selectedPackage__2JAfO{
    background-color: whitesmoke;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.SelectedPackage_selectedPackage__2JAfO >*+*{
    margin-top: 20px;
}
 

.SelectedPackage_selectedPackagePrimary__3KaoZ{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.SelectedPackage_selectedPackagePrimary__3KaoZ >*+*{
  flex: 1 1;
  margin-right: 40px;
}
.SelectedPackage_selectedPackageList_name__3Rdlw{
    flex: 4 1;
}
.SelectedPackage_selectedPackagePrimaryHeading__1gQwZ{
    font-weight: 700;
}


@media (max-width:550px) {
    .SelectedPackage_selectedPackagePrimary__3KaoZ{
       flex-direction: column;
    }
    .SelectedPackage_selectedPackagePrimary__3KaoZ >*+*{
        margin-right: 0;
        margin-top: 20px;
      }
    .SelectedPackage_selectedPackage__2JAfO{
       flex-direction: row;
    }
    .SelectedPackage_selectedPackageList_name__3Rdlw{
        flex: 10 1;
    }
    .SelectedPackage_selectedPackage__2JAfO >*+*{
        margin-top: 0;

    }

     
    
    
}
.VoucherView_voucherTitle__1Vapu {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 10px;
}

.VoucherView_voucherButtons__319oi {
  margin: 0 auto;
  display: flex;
}

.VoucherView_voucherButtons__319oi * +*{
  margin-left: 10px;
}

 
 

.VoucherView_voucherApiError__-H_sv {
  text-align: center;
  font-size: 1rem;
  margin-bottom: 10px;
  color: var(--red);
}
.VoucherView_voucherInput__1kawt input{
  padding: 11px 10px;
}
.PaymentMethodsView_payment__2jlDa {
  width: 88%;
  margin: 30px auto;
}

.PaymentMethodsView_paymentTitle__1jBp3 {
  color: var(--mainBlack);
  /*  */
  margin: 10px auto;
  text-align: left;
}

.PaymentMethodsView_paymentHelp__PeufQ {
}

.PaymentMethodsView_paymentUserNotLoggedIn__bcyJf {
  padding: 20px 0 0;
  color: var(--red);
}

.PaymentMethodsView_paymentMethodsContainer__2cLOx {
  margin-top: 30px;
  position: relative;
}

.PaymentMethodsView_paymentHideMethods__3blZh {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.7);
}

.PaymentMethodsView_paymentMethodsTitle__1zQ2O {
  
  margin: 30px auto 10px;
  color: var(--mainBlack);
  font-size: 1.5rem;
  text-align: left;
}

.PaymentMethodsView_paymentMethodHelp__1bbXH {
  
  margin: 0 auto;
}

.PaymentMethodsView_paymentMethodError__OrQPi {
  
  margin: 0 auto;
  color: var(--red);
}

.PaymentMethodsView_paymentMethods__3bpEP {
  
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--mainBlack);
  padding: 20px 20px 10px;
}

.ReceiptView_receiptView__CP4zq {
  width: 80%;
  margin: 30px auto;
}

.ReceiptView_receiptTitle__-ckaD {
  color: var(--mainBlack);
  font-size: 1.5rem;
  width: 80%;
  margin: 10px auto;
  text-align: left;
}

.ReceiptView_receiptText__13TDS {
  width: 80%;
  margin: 0 auto;
}

.ReceiptView_receiptFailureMessage__1K-4U {
  width: 80%;
  margin: 0 auto;
  color: var(--red);
}

.ReceiptView_receiptReturnPath__4zHUT {
  width: 80%;
  margin: 0 auto;
}

.ReceiptView_receiptReturnButton__3TBZK {
  border: 1px solid var(--brand);
  background-color: var(--brand);
  color: var(--brandHover);
  text-align: center;
  padding: 3px 30px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  margin-top: 20px;
}

.ReceiptView_receiptReturnButton__3TBZK:hover {
  color: var(--brand);
  background-color: var(--brandHover);
  border: 1px solid var(--brandHover);
}

.PurchaseFlow_packageMain__1HUS5 {
  width: 100%;
  display: block;
  /* margin-top: 50px; */
  padding-top: 30px;
}

.PurchaseFlow_packages__1qzoZ {
  width: 88%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.PurchaseFlow_pageState__3vaOh {
  width: 88%;
  margin: 0 auto;
}

.TOS_tosContainer__r7U34 {
  margin: 5% auto 2% auto;
  width: 80%;
  flex-grow: 1;
  color: var(--mainBlack);
}

.TOS_padded__1uafb {
  padding-left: 1%;
}
.TOS_padded__1uafb li {
  padding: 5px 0;
}

.TOS_tosMainTitle_p__3oyho {
  line-height: 28px;
}
.TOS_tosTitle__2HFmx {
  font-weight: 700;
  padding-bottom: 10px;
}
.TOS_tosMainTitle__8opD7 {
  padding-bottom: 10px;
  font-weight: 700;
}
.TOS_tosMainTitle_p__3oyho a {
  color: var(--brandHover);
}

.PrivacyPolicy_tosContainer__2bQKP {
  margin: 5% auto 2% auto;
  width: 80%;
  flex-grow: 1;
  color: var(--mainBlack);
}

.PrivacyPolicy_padded__22hxw {
  padding-left: 1%;
}
.PrivacyPolicy_padded__22hxw li {
  padding: 5px 0;
}

.PrivacyPolicy_tosMainTitle_p__2Ybk3 {
  line-height: 28px;
}
.PrivacyPolicy_tosTitle__sexAK {
  font-weight: 700;
  padding-bottom: 10px;
}
.PrivacyPolicy_tosMainTitle__3e1qs {
  padding-bottom: 10px;
  font-weight: 700;
}
.PrivacyPolicy_tosMainTitle_p__2Ybk3 a {
  color: var(--brandHover);
}

.DetailsShare_details_share__KSZIV {
  display: flex;
  /* padding-top: 35px; */
  flex-wrap: wrap;
  width: 100%;
  /* gap: 20px; */
  align-items: center;
  justify-content: flex-start;
}
.DetailsShare_details_share__KSZIV a {
  text-decoration: none;
  color: var(--brand);
}
.DetailsShare_details_share__KSZIV a:hover {
  color: var(--brandHover);
}

.DetailsShare_shareBTN__1ILU3 {
  background-repeat: no-repeat;
  background-size: cover;

  width: 8.57px;
  height: 16px;
  cursor: pointer;
  margin-right: 10px;
  color: var(--brand);
  display: flex;
  justify-content: center;
  align-items: center;
}

.DetailsVideo_details_description_container__28JRq {
  width: 88%;
  margin: 0 auto;
  background-color: whitesmoke;
  overflow: hidden;
  padding: 30px;
  box-sizing: border-box;
  color: var(--brandHover);
  margin-top: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.DetailsVideo_details_description_container__28JRq > * + * {
  margin: 23px 0;
}
.DetailsVideo_detailsDescriptionTitleContainer__Fx5_A {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--brandHover);
  /* padding: 10px; */
  overflow: hidden;
  align-items: center;
}
.DetailsVideo_details_description_title_name__2YtKs {
  /* text-transform: uppercase; */
  font-weight: bold;
  color: var(--brandHover);
  line-height: 33.6px;
}

.DetailsVideo_details_description_title_left__jxMoq {
  flex: 2 1;
  margin: 23px 0;
}
.DetailsVideo_details_description_title_right__1E_VL {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 950px) {
  .DetailsVideo_details_description_container__28JRq {
    width: 90%;
  }
}

@media (max-width: 550px) {
  .DetailsVideo_details_description_container__28JRq {
    width: 95%;
    padding: 15px;
  }
}

.side-bar {
    width: 23.2rem;
    height: 100%;
    padding: 2.1rem 1.2rem;
    background-color: #17171e;
    transition: all 0.5s ease-in-out;

    overflow:scroll;
    max-height: 100vh;
    height: 100vh;


    
  }
  .logo-name-wrapper {
    position: relative;
    margin-bottom: 2.1rem;
    display: flex;
    height: 50px;
  }
  .logo-name {
    display: flex;
    align-items: center;
  }
  .logo-name__name {
    margin-left: 0.9rem;
    white-space: nowrap;
    color: var(--mainWhite);
  }
  .logo-name__button {
    position: absolute;
    top: 50%;
    right: 0;
    
    -webkit-transform: translateY(-50%);
    
            transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  .logo-name__icon {
    
    color: var(--mainWhite);
  }
  
  .features-list {
    padding-bottom: 1rem;
    /* border-bottom: 1px solid #292932; */
    list-style: none;
  }
  .features-item {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0rem 0.9rem;
    margin-bottom: 1.1rem;
    color: var(--mainActive);
    cursor: pointer;
    transition: 0.5s ease-in-out;
    position: relative !important;
    min-height: 50px;
    max-height: 50px;
    height: 50px;
  }
  .features-item  a{
   text-decoration: none;
  }
  
   .features-item-active a::before ,.features-item a::before {
    /* transition: 0.5s ease;
    content: '';
    position: absolute;
    left: -1.2rem;
    height: 100%;
    border-left: 3px solid var(--card);
    border-radius: 2px;
    opacity: 0;
    visibility: hidden; */
  }
  .features-item-active {
      color: var(--brand);
      position: relative;
      
  }
  .features-item-active::before {
    opacity: 1 !important;
    visibility: unset !important;
}
  .features-item:hover {
    color: var(--brand);
    transition: 0.5s ease;
  }
  .features-item:hover::before {
    opacity: 1;
    visibility: unset;
  }
  
  .features-item-text {
    margin-left: 1.5rem;
    transition: opacity 0.6s ease;
    opacity: 1;
    position: relative;
  }
   
  .inbox-icon {
    position: relative;
  }
  
 
  .tooltip {
    position: absolute;
    top: -2.1rem;
    left: 6.4rem;
    background-color: white;
    color: var(--card);
    border-radius: 0.4rem;
    padding: 0.5rem 0.9rem;
    transition: 0s;
    opacity: 0;
    display: none;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    overflow: visible;
    overflow: auto;
  }
  .side-bar.collapse .message:hover .tooltip,
  .features-item:hover .tooltip,
  .category-item:hover .tooltip,
  .chat-new-btn:hover .tooltip {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    transition: all 0.5s ease;
    opacity: 1;
  }
  .side-bar.collapse {
    width: 6.4rem;
  }
  .side-bar.collapse .tooltip {
    display: block;
  }
  .side-bar.collapse .logo-name,
  .side-bar.collapse .category-header,
  .side-bar.collapse .chat-header,
  .side-bar.collapse .chat-new-btn-text,
  .side-bar.collapse .features-item-text,
  .side-bar.collapse .inbox-number,
  .side-bar.collapse .category-item-text,
  .side-bar.collapse .category-item-number,
  .side-bar.collapse .message-text,
  .side-bar.collapse .chat-item-name,
  .side-bar.collapse .chat-item-number,
  .side-bar.collapse .chat-item-status {
    /* opacity: 0; */
    display: none;
    pointer-events: none;
  }
  .side-bar.collapse .message-text {
    opacity: 0;
    display: none;
    pointer-events: none;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .side-bar.collapse .message-icon {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .side-bar.collapse .logo-name__button {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    left: 65%;

  }
   
  .side-bar.collapse .chat-item-avatar-wrapper::before {
    opacity: 1;
  }

  .side-bar.collapse .features-item{
    /* padding: 0 0.6rem ;
    margin-bottom: 0.7rem; */
  }



 

@media (max-width:550px) {
  .side-bar {
   width: 100vw;

    
  }
  .side-bar.collapse{
    width: 3.4rem;
  }
  .features-item{
  }
  
  .features-item {
    
    min-height: 40px;
    max-height: 40px;
    height: 40px;
    padding: 0  !important;

  }


}
 

  
.SideBar_navBarRow_primary_sideBar__lKYyx{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    min-width: 150px;
}




.SideBar_navBarRow_primary_sideBar__lKYyx  > * + * {
    margin-top: 20px;
  }
.NavBarButton_activeSelected__E37oh {
  color: var(--brand) !important;
}

.NavBarButton_FaIcon__3glbM {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}
.NavBarButton_navBarSearch__3Ecm3 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.NavBarButton_navBarSearch__3Ecm3 > * + * {
  margin-left: 2px;
}

.Dashboard_Dashboard__yaTTh{

    display: flex;flex: 1 1;


}

.Dashboard_DashboardSideBar__12vyL{
    /* flex: 1;
    background-color: var(--card);
    padding: 20px; */
    color: var(--mainWhite) !important;
    background-color: #17171e;


}


.Dashboard_DashboardContent__plPne{

    /* flex: 5; */
    background-color: var(--mainWhite);
    /* padding: 20px; */
    padding: 0 50px 50px 50px;
    flex: 1 1;
    /* margin-top: 50px; */
   

}



@media (max-width: 550px) {
    
    .Dashboard_DashboardContent__plPne{

        
        /* padding: 0 20px; */
        padding: 0;
        margin-top: 0;
        /* height: 100%; */
    
    }
    .Dashboard_DashboardSideBar__12vyL{
       
        padding: 0;
       
    
    
    }
    
  }
   
   
 
.ListAllSubOrg_ListAllSubOrg_item__31vQU{
    /* padding: 20px 20px; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    min-height: 350px;
    background-color: var(--brand);
    border-radius: 50px;
    margin: 0 20px;
    margin-top: 20px;

}
.ListAllSubOrg_primaryContainer__XgD6U,.ListAllSubOrg_tertiaryContainer__3Jomb{
    width: 88%;
    margin: 0 auto;
}
.ListAllSubOrg_secondaryContainer__p3gPz{
    background-color: var(--mainWhite);
   margin:50px 0;
   padding: 20px 0;
}


.ListAllSubOrg_container__tjdCL{
    display: grid;
    grid-template-columns: 0.1fr 4fr;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.ListAllSubOrg_line__3z3py{
    height: 2px;
    color: white;
    width :100%;

    background-color: var(--brand);
    
}



.ListAllSubOrg_ListAllSubOrg_name__3KbCo{
    color: var(--mainWhite);
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 20px 0; */
    padding: 20px 0;
    text-transform: uppercase;
    text-align: center;
}


.ListAllSubOrg_ListAllSubOrg_image__3g6wz{
  /* height: 290px; */
  width: 100%;
}

.ListAllSubOrg_ListAllSubOrg_image__3g6wz:hover{
    cursor: pointer;
}

.ListAllSubOrg_allLetter__13xX8{
    display: flex;
    justify-content: space-around
    ;
    align-items: center;
    margin: 70px auto 30px auto;
    flex-flow: row wrap;
    font-weight: 800;
}
.ListAllSubOrg_allLetter__13xX8 li {
    text-decoration: none;
    list-style: none;
    padding: 2px 8px;
    cursor: pointer;
    color: var(--brand);

}
.ListAllSubOrg_allLetter__13xX8 li a {
    text-decoration: none;
    color: var(--brand);

}

 
.ListAllSubOrg_allLetter_alphabet__1DEW8{
 
    margin: 50px auto;
    padding: 20px 0;
    /* margin-top: 900px; */


    /* test */
/* 
    gap: 600px;
    display: flex;
    flex-direction: column; */
}
.ListAllSubOrg_allLetter_alphabet__1DEW8 a{
    color: var(--mainBlack) !important;
    text-decoration: none;
}
.ListAllSubOrg_allLetter_alphabet_primary__2U-8F{
    display: flex;
    /* border-top: 1px solid #e3e3e3; */
    width: 100%;

    padding: 20px 10px;

    flex-direction: column;

}

 
.ListAllSubOrg_item_alphabet__1TLsj{
    font-weight: 800;
    /* min-width: 150px; */
    /* flex  :2; */
    color: var(--brand);
    width: 10px;

    
}
.ListAllSubOrg_item_content__1nAfP:hover{
    color: var(--card);
    cursor: pointer;
    transition: all 0.3s ease-in-out;

}
.ListAllSubOrg_allLetter_alphabet_item_content__2bFTu{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: start;

    align-items: center;
    grid-gap: 30px;
    gap: 30px;
    flex: 5 1;
    text-transform: uppercase;
    
    
}
.ListAllSubOrg_allLetter_alphabet_item__1JjCb >*+*{

   margin-left: 150px;
}
.ListAllSubOrg_allLetter_alphabet_item__1JjCb a{
    color: var(--mainBlack);
    text-decoration: none;
}
 
.ListAllSubOrg_allLetter_disabled__1QRwx{
    color: var(--mainWhite);
    pointer-events: none;
}
.ListAllSubOrg_allLetter_disabled__1QRwx a{
    color: var(--mainBlack) !important;
    pointer-events: none;
}
.ListAllSubOrg_item_content__1nAfP{
    color: var(--mainBlack);
}
 

@media (max-width: 550px){
    .ListAllSubOrg_allLetter_alphabet_item_content__2bFTu{
       
        grid-template-columns: 1fr 1fr ;
        
        grid-row-gap: 30px;
        
        row-gap: 30px;
        grid-column-gap: 10px;
        -webkit-column-gap: 10px;
                column-gap: 10px;
        
        
    }
    .ListAllSubOrg_allLetter_alphabet_primary__2U-8F{
        flex-direction: column;

    
        padding: 20px 0px;
    
    }
    
    
    .ListAllSubOrg_allLetter_alphabet_item__1JjCb{

       flex-direction: column;
       padding: 20px 5px;

    }.ListAllSubOrg_allLetter_alphabet_item__1JjCb > * + *{

     margin-top: 20px;
     margin-left: 0;
       
    }
    .ListAllSubOrg_ListAllSubOrg_item__31vQU{
    margin: 0;
        
    }
     
    .ListAllSubOrg_primaryContainer__XgD6U,.ListAllSubOrg_tertiaryContainer__3Jomb{
        width: 94%;
    }
    .ListAllSubOrg_container__tjdCL{
        grid-template-columns: 0.2fr 4fr;
        
    }
 

}


.DashboardMainPage_DashboardMainPage__2AvqR{
    display: flex;
justify-content: center;
flex-direction: column;
align-items: center;



}

.DashboardMainPage_DashboardMainPage_welcome__j0Gah{
    padding: 50px 50px;

    width: 100%;
    height: 60vh;

}



@media (max-width: 600px) {
    .DashboardMainPage_DashboardMainPage_welcome__j0Gah{
      
        padding: 20px 0px;
    /* height: 45vh; */

    }
    
    
  }
  
.Subscription_subTitle__2MgkF {
  font-weight: bold;
  color: var(--brandHover);
}

.Subscription_item__3TFmh {
  margin-top: 12px;
  margin-bottom: 12px;
  border: solid 1px;
  border-radius: 12px;
  padding: 12px;
}

.Subscription_itemName__3tBAL {
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Subscription_inner__1Y1pa {
  padding-left: 12px;
}

.Subscription_innerRow__1nEvu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Subscription_innerValue__czWw6 {
  width: 50%;
}

.Subscription_innerValueTickets__30iv8 {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.CurrentSubscription_title__2dlYo {
  font-weight: bold;
}

.CurrentSubscription_subTitle__3LW7a {
  font-weight: bold;
  color: var(--mainWhite);
}

.CurrentSubscription_item__1c1px {
  margin-top: 12px;
  margin-bottom: 12px;
  border: solid 1px;
  border-radius: 12px;
  padding: 12px;
}

.CurrentSubscription_tvodItemRow__1WZMd {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  margin-bottom: 12px;
  border: solid 1px;
  border-radius: 12px;
  padding: 12px;
  justify-content: space-between;
}

.CurrentSubscription_dateTitle__AzAwm {
  margin-right: 25px;
}

.CurrentSubscription_tvodTitleRow__3nZBO {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.CurrentSubscription_itemName__1andL {
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CurrentSubscription_inner__1xCSA {
  padding-left: 12px;
}

.CurrentSubscription_innerRow__22NCU {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.CurrentSubscription_innerValue__3MooI {
  width: 50%;
}

.CurrentSubscription_innerValueTickets__3fHS3 {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.CurrentSubscription_dateRow__2u5wU {
  display: flex;
  flex-direction: row;
}

.CurrentSubscription_separator__3S9Ww {
  margin-left: 5px;
  margin-right: 5px;
}

.CurrentSubscription_placeholder__2sJuj {
  text-align: center;
}

.AdminUsers_projects_details__1QMKK{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    background-color: var(--card);
    color: var(--mainWhite);
}

.AdminUsers_projects_detail__2y-RF{
    display: flex;
    padding: 10px 20px;
}
.AdminUsers_project_info__action__ov3nU,
  .AdminUsers_projects_info__name__T24VW,
  .AdminUsers_project_info__created__mViMt,.AdminUsers_projects_info__order_number__2kMTQ ,.AdminUsers_project_info__name__3eZ__{
    flex: 1 1;
  }


  .AdminUsers_projects_detail__2y-RF {
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* padding: 10px 20px; */
 
  }

 
  .AdminUsers_project_info__action__ov3nU {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
  }


  .AdminUsers_project_info__action__ov3nU button,
  .AdminUsers_project_info__action__ov3nU a{
      padding: 10px 28px;
  }
   
  
  .AdminUsers_projects_detail__2y-RF:nth-child(even) {
    /* background-color: hsl(0, 0%, 48%); */


  }

  .AdminUsers_projects_detail__2y-RF:hover{
    background-color: whitesmoke;
    
  }


  .AdminUsers_project_notFound__2sVG7 {
    padding: 30px;
    text-align: center;
    font-size: 30px;
  }

  @media (max-width:850px) {
    .AdminUsers_projects_info__order_number__2kMTQ,.AdminUsers_projects_info__name__T24VW,.AdminUsers_project_info__name__3eZ__ {
      display: none;
    }
    .AdminUsers_project_info__action__ov3nU button,
    .AdminUsers_project_info__action__ov3nU a{
        padding: 5px ;
    }

    .AdminUsers_project_info__created__mViMt{
      max-width: 50%;
      overflow: scroll;
    }
    
  }

  .SearchEvent_searchContainer__1UBMC {
    display: flex;
    flex-direction: column;
    color: var(--mainBlack);
    width: 80%;
    margin: 0 auto;
    padding: 20px 40px;
  }
  #SearchEvent_searchForm__3X_LF {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }


  .SearchEvent_searchRow__2QtEr {
    margin-bottom: 1%;
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 100%;
  }

  .SearchEvent_searchColumn__1ylUE {
    display: flex;
    flex-direction: column;
    margin-right: 2%;
    min-height: 50px;
    max-height: 50px;
    height: 50px;
    width: 100%;
  }
  .SearchEvent_searchField__1GzQF {
    padding: 18px 0;
    min-height: 50px;
    max-height: 50px;
    height: 50px;
    text-align: center;
    border-radius: 12px;
  }
  
  .SearchEvent_searchButton__QmT_Y {
    color: var(--white);
    background-color: var(--brand);
    min-height: 50px;
    max-height: 50px;
    height: 50px;
    display: flex;
    align-items: center;
  }
  .SearchEvent_searchButton__QmT_Y:disabled{
    background-color: var(--mainActive);
  }
  .SearchEvent_searchButton__QmT_Y:disabled:hover{
    background-color: var(--mainActive);
    border: 0px;

  }
  .SearchEvent_FaIcon__2vCCd {
    margin-right: 5px;
  }
  
  .SearchEvent_searchButton__QmT_Y:hover {
    background-color: var(--brand);
    border: 1px solid var(--mainWhite);
  }

  .SearchEvent_NoDataAvailable__f3jNH{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .SearchEvent_NoDataAvailable__f3jNH img{
    height: 300px;
  }


  @media (max-width: 1180px) {
    .SearchEvent_searchRow__2QtEr {
      width: 100%;
    }
    
  }

  @media (max-width: 700px) {
    .SearchEvent_searchContainer__1UBMC {
      width: 95%;
      padding: 20px 20px;
    }
  }
  @media (max-width: 700px) {
    .SearchEvent_searchButton__QmT_Y,
    .SearchEvent_searchResultBTN__3mKwf,
    .SearchEvent_searchResultBTNactive__3HVli {
      padding: 10px 20px !important;
    }
  }

  @media (max-width: 550px) {
    .SearchEvent_searchContainer__1UBMC {
      padding: 5px;
    }
    .SearchEvent_searchRow__2QtEr {
      width: 95%;
      padding: 0px;
      margin: 0 auto;
    }
    .SearchEvent_row__PtO7H {
      flex-direction: column !important;
      justify-content: center;
      align-items: center;
    }
  }
  
.Scoreboard_scoreboard-item--topctabutton__UL5HY {
    margin: 0;
    background: var(--cardbackground) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
    padding: 2px;
    border-radius: 12px;
}
.Scoreboard_scoreboard-item--topcta__133xD {
    /* display: grid;
    /* grid-template-columns: 1fr 1fr 1fr; */
    /* grid-template-columns: 1fr 1fr ; */
    display: flex;
    /* gap: 20px; */
    justify-content: space-between;
}
.Scoreboard_scoreboard-item--topcta_item__2ZGzU {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Scoreboard_scoreboard-item--topcta_items__2e7jx {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    background-color: var(--modalBackground);
    padding: 10px;
    border-radius: 12px;
}
.Scoreboard_scoreboard-item--topctabutton__UL5HY button {
    padding: 15px;
    background: black;
    color: white;
}

.Scoreboard_ScoreboardItem__1Dlmo {
    /* max-width: 600px; */
    display: flex;
    flex-direction: column;
    background-color: var(--card);
    padding: 50px 20px;
    grid-gap: 20px;
    gap: 20px;
    color: var(--mainWhite);
    /* padding-top: 0; */
    /* height: 500px; */
}

.Scoreboard_bottomCTA__13Qhg {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
    margin: 20px 0;
}

.Scoreboard_ScoreboardItemHeader__1xiMp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    /* margin: 0 10px; */
}

.Scoreboard_ScoreboardItemHeader__1xiMp img {
    height: 100px !important ;
    width: 178px !important;
    mix-blend-mode: multiply;
}

.Scoreboard_ScoreboardItem__1Dlmo input {
    /* height: 40px; */
    padding: 10px 10px;
    text-align: center;
    margin: 2px auto;
    font-size: 2em;
    width: 100%;
    text-align: center;
    padding: 0;
}

.Scoreboard_teamNameButton__2yi3C button {
    padding: 10px;
    width: 100%;
    font-family: "UI-Black";
    align-self: center;
    font-size: 0.8em;
    border-radius: 0;
    background-color: #54af3c;
    /* background-color: #54af3c; */
    color: var(--mainWhite);
    font-weight: bolder;
}
.Scoreboard_teamNameButton__2yi3C button:hover {
    opacity: 0.8;
    transition: all 0.5s ease-in-out;
}
.Scoreboard_teamNameButton__2yi3C button:disabled {
    opacity: 0.5;
    background-color: grey !important;
}

.Scoreboard_decreaseScoreButton__1TgJj {
    background-color: var(--red) !important;
}
.Scoreboard_scoreboard-item--topcta_items_score__1z--p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    grid-gap: 5px;
    gap: 5px;
}
.Scoreboard_scoreboard-item--topcta_items_button_item__2OWGO {
    display: flex;
    justify-content: space-between;
    align-items: center;

    grid-gap: 2px;

    gap: 2px;
    width: 100%;
}

.Scoreboard_scoreboard-time__3DJXq {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    border-radius: 12px;
    padding: 10px;
    background-color: var(--modalBackground);
    flex-direction: column;
}
.Scoreboard_scoreboard-time-container__1Es9w {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
}

@media (max-width: 950px) {
    .Scoreboard_ScoreboardItem__1Dlmo {
        height: auto;
    }
}
@media (max-width: 550px) {
    .Scoreboard_ScoreboardItem__1Dlmo {
        padding: 10px;
    }
    .Scoreboard_scoreboard-item--topcta__133xD {
        grid-gap: 10px;
        gap: 10px;
    }
}

.AdminEvent_EventTimeDescription__3uBbb {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.AdminEvent_EventPrimary_date__19KOD,
.AdminEvent_EventPrimary_time__i1MbQ {
    display: flex;
    align-items: center;
}
.AdminEvent_EventPrimary_date__19KOD > * + * {
    margin-left: 20px;
}
.AdminEvent_EventPrimary_time__i1MbQ > * + * {
    margin-left: 20px;
}
.AdminEvent_EventTimeDescription__3uBbb > * + * {
    margin-top: 30px;
}

.AdminEvent_EventPrimary_description__3t7xm > * {
    margin: 30px 0;
    line-height: 200%;
}

.AdminEvent_videoScoreBoard__dsUiE {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    /* height: 500px; */
    margin: 0;
    /* background-color: black; */
}
.AdminEvent_ScoreBoardMobile__2gjNU {
    display: none;
}

@media (max-width: 950px) {
    .AdminEvent_videoScoreBoard__dsUiE {
        flex-direction: column;
        height: auto;
    }
    .AdminEvent_ScoreBoardMobile__2gjNU {
        display: block;
        margin-top: 20px;
    }
    .AdminEvent_ScoreBoardDesktop__14yDP {
        display: none;
    }
}

.HeaderNavBar_HeaderNavBar__1nrcG{
    display: flex;
   
    align-items: center;
    padding: 15px 0;
    justify-content: space-around;
    text-transform: uppercase;
    color: var(--mainWhite);
}

.HeaderNavBar_HeaderNavBar_services__1D6I2{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.HeaderNavBar_HeaderNavBar_some__3r9Rz{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.HeaderNavBar_HeaderNavBar_services_item__31YOg{
    margin-right: 40px;
}
.HeaderNavBar_HeaderNavBar_some_item__2_fZo{
    margin-right: 10px;


}

.HeaderNavBar_salibandytv_logo_navbar_header__3wiJK{
    height: 100%;
    width: 100%;
    max-height: 200px;
    object-fit: contain;
}
.HeaderNavBar_HeaderNavBar_container__3aSwY{
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 0 auto;
}
.HeaderNavBar_some_Links_icons__3Eziv{
    color: var(--mainWhite);
    text-decoration: none;

}
.HeaderNavBar_some_Links_icons__3Eziv:hover{
    color: var(--brand);
    text-decoration: none;
    transition: 0.1s ease;

}

@media (max-width: 1200px) {
    .HeaderNavBar_HeaderNavBar_container__3aSwY{
        width: 80%;
    }
    
}


@media (max-width: 991px) {
    .HeaderNavBar_HeaderNavBar_container__3aSwY{
        width: 100%;
    }

    .HeaderNavBar_HeaderNavBar_services_item__31YOg{
        margin-right: 20px;
    }
    
    .HeaderNavBar_HeaderNavBar__1nrcG{
        justify-content: space-evenly;
    }
    
    
}
@media (max-width: 650px) {
     
    
    .HeaderNavBar_HeaderNavBar__1nrcG{
      flex-direction: column;
      justify-content: center;
      align-items: center;
      grid-gap: 10px;
      gap: 10px;
    }
    .HeaderNavBar_HeaderNavBar_services__1D6I2{
        flex-wrap: wrap;
        grid-gap: 10px;
        gap: 10px;
    }
    
    
    
}
.NavBar_navBarRow__2RXra {
  display: flex;
  width: 100%;
  align-items: center;
  font-weight: 400;
  justify-content: space-between;
  background-color: var(--card);
  padding: 15px 0;

  
}
.NavBar_navBar__2PBXG {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  align-items: center;
  color: var(--mainWhite);

  z-index: 5;
  margin: 0 auto;


  /* // new changes */
  /* grid-template-columns: 4fr 1fr;
  align-items: flex-end; */
 
}
.NavBar_navBar_Primary__3MIRM{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 88%;
  margin: 0 auto;
  /* // new changes */
/* order: 1;
width: 100%; */

}
.NavBar_navBar__2PBXG:first-child a {
  z-index: 9999999 !important;
}

.NavBar_navBarRow_primary__WPpMl {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 88%;
  margin: 0 auto;
}
.NavBar_navBarRow_primary__WPpMl li,
.NavBar_navRight__X1u8n li {
  text-decoration: none;
  list-style: none;
  color: var(--mainWhite);
}
.NavBar_navBarRow_primary__WPpMl li a,
.NavBar_navRight__X1u8n li a {
  text-decoration: none;
  list-style: none;
  color: var(--mainWhite);

  position: relative;
}

.NavBar_navRight__X1u8n {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  flex: 2 1;
  justify-content: flex-end;
  align-items: center;

  /* new changes */
  /* flex-direction: column;
  justify-content: center;
  align-items: center; */
}
.NavBar_navRight__X1u8n > * + * {
    /* // new changes */
  /* margin-top: 10px !important; */
  margin-left: 10px;
}

 
@media (max-width: 991px) {
  
  .NavBar_navBarRow__2RXra {
    background-color: var(--mainWhite);
    padding:   0;
    /* new changes */
    /* position: relative; */


  }
  .NavBar_navBarRow_primary__WPpMl {
   width: 100%;
  }
  
   
  .NavBar_navBarRow_primary__WPpMl li a,
  .NavBar_navRight__X1u8n li a {
    
    color: var(--card);
    
  }
  .NavBar_navBar__2PBXG {
    color: var(--card);
    grid-template-columns: 1fr;

   
  }
  .NavBar_navBar_Primary__3MIRM{
   
    display: none;
   
  }
  
  
  
}

.loginContainer,
.signUpContainer {
  cursor: pointer;
  display: flex;
  justify-content: center;align-items: center;

 
}
 
.profileContainerItem{
    height: 40px;
}
.loginContainer > * + * {
  margin-left: 15px;
  
}

.loginBTN{
    background-color: var(--brand);
    /* padding: 14px 56px; */
    border-radius: 50px;

}

.singupBTN{
    /* padding: 10px 56px; */
    background-color: var(--card);
    border-radius: 50px;
    height: 40px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;


    



}

.smallScreenLoginBTN{
  color: var(--brand);
  padding: 0 10px;
}


/* profile button stuff */
.profile-dropdown {
    position: relative;
    background: var(--brand);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999999;

    /* Likely future */
  }
 
  .profile-dropdown * {
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    /* Likely future */
  }
 

  .profile-dropdown-active input{
      display: none;
  }
  .profile-dropdown input[type=checkbox] {
    display: none;
  }
  .profile-dropdown input[type=checkbox]:checked ~ ul {
    display: block;
    -webkit-animation: pulse 0.5s;
            animation: pulse 0.5s;
  }
  .profile-dropdown input[type=checkbox]:checked ~ img {
    background: var(--card);
  }
  .profile-dropdown input[type=checkbox]:checked ~ label {
    background: var(--brand);
  }
  .profile-dropdown input[type=checkbox]:checked ~ label i {
    color: var(--card);
  }
  .profile-dropdown input[type=checkbox]:checked ~ label:after {
    content: "";
    position: absolute;
    top: 100%;
    right: calc(50% - 10px);
    display: block;
    border-style: solid;
    border-width: 7px 10px 0 10px;
    border-color: var(--brand) transparent transparent transparent;
    width: 0;
    height: 0;
  }
  .profile-dropdown img {
    display: inline-block;
    background: #d9d9d9;
    height: 2.5rem;
    vertical-align: middle;
    margin-right: 1rem;
    margin: 0.5rem 0.75rem 0.5rem 0.5rem;
    border-radius: 50%;
  }
  .profile-dropdown span {
    display: inline-block;
    vertical-align: sub;
    width: 125px;
    margin-right: 2rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
  }
  .profile-dropdown ul {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #fff;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    border-radius: 3px;
  }
  .profile-dropdown ul li a {
    padding: 0.75rem 1rem;
    text-decoration: none;
    color: var(--brand);
    display: flex;
   align-items: center;
   grid-gap :1px;
   gap :1px;

    
  }
  .profile-dropdown ul li a i {
    /* font-size: 1.3rem; */
    vertical-align: middle;
    margin: 0 0.75rem 0 -0.25rem;
  }
  .profile-dropdown ul li a:hover {
    background: var(--card);
  }
  .profile-dropdown ul li:first-child a:hover {
    border-radius: 3px 3px 0 0;
  }
  .profile-dropdown ul li:last-child a:hover {
    border-radius: 0 0 3px 3px;
  }
  .profile-dropdown > label {
    position: relative;
    height: 3.5rem;
    display: block;
    text-decoration: none;
    background: transparent;
    color: var(--mainWhite);
    box-sizing: border-box;
    padding: 0.9rem;
    float: right;
    border-radius: 0 3px 3px 0;
  }
  .profile-dropdown > label i {
    color: #b2b2b2;
    /* font-size: 1.75rem; */
  }
  .profile-dropdown:after {
    content: "";
    display: table;
    clear: both;
  }
  
 
 
 
  .containerprofile:after {
    content: "";
    display: table;
    clear: both;
  }
  


  /* // these are custom for login dropdown */
 .profile-dropdown1{
  border-radius: 50px;


  /* new changes */
  /* width: 175px;
  max-width: 175px;
  min-width: 175px; */
 }
  
 .label1{
  padding: 10px 5px 10px 0 !important;
  height: auto !important;
 }
 .profile-dropdown1 span{
 
  margin-right: 0rem !important;
  text-align: center !important;
 width: 120px;
  /* padding-right: 1rem; */
 }
 .profile-dropdown1 img{
  display: none;
 }
  

  @media (max-width: 991px) {
    .profile-dropdown span {
      display: none;
    }

  .profile-dropdown1{
    border-radius: 0;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    /* background-color: white; */
  }
  
  .profile-dropdown img{
    display: flex;
    background-color: var(--mainWhite);
   }

    
    .profile-dropdown > label {
      
      padding: 0.9rem 0;
      
    }
    .profile-dropdown input[type=checkbox]:checked ~ ul {
     width: 150px;
    }
    .profileContainer{
       justify-content: flex-end;
       align-items: flex-end;
       height: auto;
    }
    .profileContainerItem{
        height: auto;
        width: auto;
        min-width: auto;
    }
    .signUpContainer, .loginContainer—{
        width: auto;
  max-width: auto;
  min-width: auto;
    }
  }
  
