.button {
     
color: white;
cursor: pointer;
text-align: center;

 padding: 10px 56px;
 text-decoration: none;
 border: none;
  font-size: var(--font16);
  border-radius: 12px;
  background-color:var( --brand);
  }
  
  .button:focus {
    outline: none;
  }
  
  .button:hover,
  .button:active {
  background-color:var( --brandHover);


  }
  
  .button--inverse {
    background: var(--mainWhite);
    color: var(--brand);
    border: 1px solid var(--brand);
  }
  
  .button--inverse:hover,
  .button--inverse:active {
    color: var(--mainWhite);
    border: 1px solid var(--mainWhite);
    background-color: var(--brand);

  }
  
  .button--danger {
    background: #830000;
    border-color: #830000;
  }
  
  .button--danger:hover,
  .button--danger:active {
    background: #f34343;
    border-color: #f34343;
  }
  
  .button:disabled,
  .button:hover:disabled,
  .button:active:disabled {
    background: #ccc;
    color: #979797;
    border-color: #ccc;
    cursor: not-allowed;
  }
  
  .button--form{
    height: 50px;
  }



  @media (max-width: 550px) {
    
    .button--form{
      height: 40px;
    }
  
  }
   