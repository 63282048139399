.Events_main_info {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  justify-content: space-between;
  gap: 10px;
}
.eventsItem1 {
  color: var(--brand);
}
.eventsItem1_hideCarousel {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 275px));
  align-items: center;
  column-gap: 30px;
  row-gap: 20px;
}

.eventsItem1_primary,
.eventsItem1_card,
.eventsItem1_image,
.eventsItem1_secondary,
.eventsItem1_heading,
.eventsItem1_card_admin,
.eventsItem1_primary_admin {
  display: flex;
  justify-content: center;
  align-items: center;
}

.eventsItem1_card {
  min-height: 200px;
  border-radius: 50px;
  flex-direction: column;

  background-color: var(--card);
}
.eventsItem1_text {
  text-align: center;
}

.eventsItem1_primary {
  display: flex;
  flex: 2;
  border-radius: 50px;
  background-color: var(--mainWhite);
  width: 100%;
  flex-direction: column;
  gap: 10px;
  min-height: 150px;
}

.eventsItem1_secondary {
  flex: 1;
}

.eventsItem1_image {
  width: 100%;
  justify-content: space-evenly;
}
.eventsItem1_image img {
  width: 90px;
  max-height: 80px;
}

.eventsItem1_heading {
  gap: 10px;
}

/* admin stuff */
.eventsItem2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 200px));

  align-items: center;
  column-gap: 20px;
  row-gap: 20px;
}
.eventsItem1_card_admin {
  border-radius: 20px;
  min-height: 200px;
  flex-direction: column;

  background-color: var(--card);
  border: 1px solid var(--card);
}

.eventsItem1_primary_admin {
  display: flex;
  flex: 2;
  border-radius: 20px;
  background-color: var(--mainWhite);
  width: 100%;
  flex-direction: column;
  gap: 10px;
}

.eventsItem1_primary_admin:hover,
.eventsItem1_card:hover {
  cursor: pointer;
}

.NoDataAvailable {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.NoDataAvailable img {
  height: 200px;
}
@media (max-width: 990px) {
  .eventsItem1_hideCarousel {
    justify-content: center;
  }
}

@media (max-width: 550px) {
  /* admin stuff here only 1  item per rowc */
  .eventsItem2 {
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 300px));
  }
}
