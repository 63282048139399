.EventTimeDescription {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.EventPrimary_date,
.EventPrimary_time {
    display: flex;
    align-items: center;
}
.EventPrimary_date > * + * {
    margin-left: 20px;
}
.EventPrimary_time > * + * {
    margin-left: 20px;
}
.EventTimeDescription > * + * {
    margin-top: 30px;
}

.EventPrimary_description > * {
    margin: 30px 0;
    line-height: 200%;
}

.videoScoreBoard {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    /* height: 500px; */
    margin: 0;
    /* background-color: black; */
}
.ScoreBoardMobile {
    display: none;
}

@media (max-width: 950px) {
    .videoScoreBoard {
        flex-direction: column;
        height: auto;
    }
    .ScoreBoardMobile {
        display: block;
        margin-top: 20px;
    }
    .ScoreBoardDesktop {
        display: none;
    }
}
