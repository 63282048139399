.HeaderNavBar{
    display: flex;
   
    align-items: center;
    padding: 15px 0;
    justify-content: space-around;
    text-transform: uppercase;
    color: var(--mainWhite);
}

.HeaderNavBar_services{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.HeaderNavBar_some{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.HeaderNavBar_services_item{
    margin-right: 40px;
}
.HeaderNavBar_some_item{
    margin-right: 10px;


}

.salibandytv_logo_navbar_header{
    height: 100%;
    width: 100%;
    max-height: 200px;
    object-fit: contain;
}
.HeaderNavBar_container{
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 0 auto;
}
.some_Links_icons{
    color: var(--mainWhite);
    text-decoration: none;

}
.some_Links_icons:hover{
    color: var(--brand);
    text-decoration: none;
    transition: 0.1s ease;

}

@media (max-width: 1200px) {
    .HeaderNavBar_container{
        width: 80%;
    }
    
}


@media (max-width: 991px) {
    .HeaderNavBar_container{
        width: 100%;
    }

    .HeaderNavBar_services_item{
        margin-right: 20px;
    }
    
    .HeaderNavBar{
        justify-content: space-evenly;
    }
    
    
}
@media (max-width: 650px) {
     
    
    .HeaderNavBar{
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
    .HeaderNavBar_services{
        flex-wrap: wrap;
        gap: 10px;
    }
    
    
    
}