.loginContainer,
.signUpContainer {
  cursor: pointer;
  display: flex;
  justify-content: center;align-items: center;

 
}
 
.profileContainerItem{
    height: 40px;
}
.loginContainer > * + * {
  margin-left: 15px;
  
}

.loginBTN{
    background-color: var(--brand);
    /* padding: 14px 56px; */
    border-radius: 50px;

}

.singupBTN{
    /* padding: 10px 56px; */
    background-color: var(--card);
    border-radius: 50px;
    height: 40px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;


    



}

.smallScreenLoginBTN{
  color: var(--brand);
  padding: 0 10px;
}


/* profile button stuff */
.profile-dropdown {
    position: relative;
    background: var(--brand);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999999;

    /* Likely future */
  }
 
  .profile-dropdown * {
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    /* Likely future */
  }
 

  .profile-dropdown-active input{
      display: none;
  }
  .profile-dropdown input[type=checkbox] {
    display: none;
  }
  .profile-dropdown input[type=checkbox]:checked ~ ul {
    display: block;
    animation: pulse 0.5s;
  }
  .profile-dropdown input[type=checkbox]:checked ~ img {
    background: var(--card);
  }
  .profile-dropdown input[type=checkbox]:checked ~ label {
    background: var(--brand);
  }
  .profile-dropdown input[type=checkbox]:checked ~ label i {
    color: var(--card);
  }
  .profile-dropdown input[type=checkbox]:checked ~ label:after {
    content: "";
    position: absolute;
    top: 100%;
    right: calc(50% - 10px);
    display: block;
    border-style: solid;
    border-width: 7px 10px 0 10px;
    border-color: var(--brand) transparent transparent transparent;
    width: 0;
    height: 0;
  }
  .profile-dropdown img {
    display: inline-block;
    background: #d9d9d9;
    height: 2.5rem;
    vertical-align: middle;
    margin-right: 1rem;
    margin: 0.5rem 0.75rem 0.5rem 0.5rem;
    border-radius: 50%;
  }
  .profile-dropdown span {
    display: inline-block;
    vertical-align: sub;
    width: 125px;
    margin-right: 2rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
  }
  .profile-dropdown ul {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #fff;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    border-radius: 3px;
  }
  .profile-dropdown ul li a {
    padding: 0.75rem 1rem;
    text-decoration: none;
    color: var(--brand);
    display: flex;
   align-items: center;
   gap :1px;

    
  }
  .profile-dropdown ul li a i {
    /* font-size: 1.3rem; */
    vertical-align: middle;
    margin: 0 0.75rem 0 -0.25rem;
  }
  .profile-dropdown ul li a:hover {
    background: var(--card);
  }
  .profile-dropdown ul li:first-child a:hover {
    border-radius: 3px 3px 0 0;
  }
  .profile-dropdown ul li:last-child a:hover {
    border-radius: 0 0 3px 3px;
  }
  .profile-dropdown > label {
    position: relative;
    height: 3.5rem;
    display: block;
    text-decoration: none;
    background: transparent;
    color: var(--mainWhite);
    box-sizing: border-box;
    padding: 0.9rem;
    float: right;
    border-radius: 0 3px 3px 0;
  }
  .profile-dropdown > label i {
    color: #b2b2b2;
    /* font-size: 1.75rem; */
  }
  .profile-dropdown:after {
    content: "";
    display: table;
    clear: both;
  }
  
 
 
 
  .containerprofile:after {
    content: "";
    display: table;
    clear: both;
  }
  


  /* // these are custom for login dropdown */
 .profile-dropdown1{
  border-radius: 50px;


  /* new changes */
  /* width: 175px;
  max-width: 175px;
  min-width: 175px; */
 }
  
 .label1{
  padding: 10px 5px 10px 0 !important;
  height: auto !important;
 }
 .profile-dropdown1 span{
 
  margin-right: 0rem !important;
  text-align: center !important;
 width: 120px;
  /* padding-right: 1rem; */
 }
 .profile-dropdown1 img{
  display: none;
 }
  

  @media (max-width: 991px) {
    .profile-dropdown span {
      display: none;
    }

  .profile-dropdown1{
    border-radius: 0;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    /* background-color: white; */
  }
  
  .profile-dropdown img{
    display: flex;
    background-color: var(--mainWhite);
   }

    
    .profile-dropdown > label {
      
      padding: 0.9rem 0;
      
    }
    .profile-dropdown input[type=checkbox]:checked ~ ul {
     width: 150px;
    }
    .profileContainer{
       justify-content: flex-end;
       align-items: flex-end;
       height: auto;
    }
    .profileContainerItem{
        height: auto;
        width: auto;
        min-width: auto;
    }
    .signUpContainer, .loginContainer—{
        width: auto;
  max-width: auto;
  min-width: auto;
    }
  }
  