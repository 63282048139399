.Footer {
  display: flex;
  width: 88%;
  margin: 0 auto;
  line-height: 20px;
  padding: 50px 0px 50px 0px;
  /* color: var(--mainWhite) !important; */
  color: var(--brand);
}

.Footer_Description_Heading,
.Footer_Menu_Heading,
.Footer_SocialMedia_Heading {
  height: 52px;
  letter-spacing: 0.5px;
  font-weight: 500;
  color: var(--brand);


}

.Footer_Menu_Heading,
.Footer_SocialMedia_Heading {
  display: flex;
  align-items: center;
}
.Footer_Menu a,
.Footer_SocialMedia a {
  color: var(--mainWhite);
  text-decoration: none;

}
.Footer_Menu a:hover,
.Footer_SocialMedia a:hover {
  color: var(--brand);
  text-decoration: none;
  transition: 0.1s ease;
}
.Footer_Menu,
.Footer_SocialMedia {
  flex: 1;
}

.Footer_SocialMedia_Heading_Info,
.Footer_Menu_info {
  display: flex;
  flex-direction: column;
  letter-spacing: 1px;
  color: var(--mainWhite);
  /* gap: 10px; */
  align-items: flex-start;
  justify-content: center;
  color: var(--mainWhite);


}
.Footer_SocialMedia_Heading_Info > * + * {
  margin-top: 10px !important;
}
.Footer_Menu_info > * + * {
  margin-top: 10px !important;
}

.Footer_Description {
  color: var(--mainWhite);


  flex: 3;

}
.Footer_Description >*+*{
  margin-top: 20px;
}
.Footer_Description_Heading_Info {
  padding: 10px 50px 10px 0;
}
.Footer_SocialMedia_Links {
  display: flex;
  /* gap: 20px; */
  justify-content: center;
  align-items: center;
  color: var(--mainWhite);

}

.Footer_SocialMedia_Links > * + * {
  margin-left: 10px;
}
.Footer_SocialMedia_Links_icons {
  color: var(--mainWhite);

}
/* media Queeries */
@media (max-width: 1200px) {
  .Footer {
    width: 100%;
    padding: 0 20px 20px 10px;
  }
}
@media (max-width: 600px) {
  .Footer {
    flex-direction: column;
    /* gap: 30px; */
    width: 95%;
    margin-top: 50px;
    /* padding-bottom: 150px !important; */
  }
  .Footer_Description_Heading_Info {
    padding: 10px 10px 10px 0;
  }
  .Footer_SocialMedia_Heading_Info,
  .Footer_Menu_info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    /* gap: 0px; */
  }
  .Footer_SocialMedia_Heading_Info > * + * {
    margin-top: 0px !important;
    margin-left: 10px !important;
  }
  .Footer_Menu_info > * + * {
    margin-top: 0px !important;
    margin-left: 10px !important;
  }
}

