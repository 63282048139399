.activeSelected {
  color: var(--brand) !important;
}

.FaIcon {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}
.navBarSearch {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.navBarSearch > * + * {
  margin-left: 2px;
}
