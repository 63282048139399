.DashboardMainPage{
    display: flex;
justify-content: center;
flex-direction: column;
align-items: center;



}

.DashboardMainPage_welcome{
    padding: 50px 50px;

    width: 100%;
    height: 60vh;

}



@media (max-width: 600px) {
    .DashboardMainPage_welcome{
      
        padding: 20px 0px;
    /* height: 45vh; */

    }
    
    
  }
  