.side-bar {
    width: 23.2rem;
    height: 100%;
    padding: 2.1rem 1.2rem;
    background-color: #17171e;
    transition: all 0.5s ease-in-out;

    overflow:scroll;
    max-height: 100vh;
    height: 100vh;


    
  }
  .logo-name-wrapper {
    position: relative;
    margin-bottom: 2.1rem;
    display: flex;
    height: 50px;
  }
  .logo-name {
    display: flex;
    align-items: center;
  }
  .logo-name__name {
    margin-left: 0.9rem;
    white-space: nowrap;
    color: var(--mainWhite);
  }
  .logo-name__button {
    position: absolute;
    top: 50%;
    right: 0;
    
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  .logo-name__icon {
    
    color: var(--mainWhite);
  }
  
  .features-list {
    padding-bottom: 1rem;
    /* border-bottom: 1px solid #292932; */
    list-style: none;
  }
  .features-item {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0rem 0.9rem;
    margin-bottom: 1.1rem;
    color: var(--mainActive);
    cursor: pointer;
    transition: 0.5s ease-in-out;
    position: relative !important;
    min-height: 50px;
    max-height: 50px;
    height: 50px;
  }
  .features-item  a{
   text-decoration: none;
  }
  
   .features-item-active a::before ,.features-item a::before {
    /* transition: 0.5s ease;
    content: '';
    position: absolute;
    left: -1.2rem;
    height: 100%;
    border-left: 3px solid var(--card);
    border-radius: 2px;
    opacity: 0;
    visibility: hidden; */
  }
  .features-item-active {
      color: var(--brand);
      position: relative;
      
  }
  .features-item-active::before {
    opacity: 1 !important;
    visibility: unset !important;
}
  .features-item:hover {
    color: var(--brand);
    transition: 0.5s ease;
  }
  .features-item:hover::before {
    opacity: 1;
    visibility: unset;
  }
  
  .features-item-text {
    margin-left: 1.5rem;
    transition: opacity 0.6s ease;
    opacity: 1;
    position: relative;
  }
   
  .inbox-icon {
    position: relative;
  }
  
 
  .tooltip {
    position: absolute;
    top: -2.1rem;
    left: 6.4rem;
    background-color: white;
    color: var(--card);
    border-radius: 0.4rem;
    padding: 0.5rem 0.9rem;
    transition: 0s;
    opacity: 0;
    display: none;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    overflow: visible;
    overflow: auto;
  }
  .side-bar.collapse .message:hover .tooltip,
  .features-item:hover .tooltip,
  .category-item:hover .tooltip,
  .chat-new-btn:hover .tooltip {
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.5s ease;
    opacity: 1;
  }
  .side-bar.collapse {
    width: 6.4rem;
  }
  .side-bar.collapse .tooltip {
    display: block;
  }
  .side-bar.collapse .logo-name,
  .side-bar.collapse .category-header,
  .side-bar.collapse .chat-header,
  .side-bar.collapse .chat-new-btn-text,
  .side-bar.collapse .features-item-text,
  .side-bar.collapse .inbox-number,
  .side-bar.collapse .category-item-text,
  .side-bar.collapse .category-item-number,
  .side-bar.collapse .message-text,
  .side-bar.collapse .chat-item-name,
  .side-bar.collapse .chat-item-number,
  .side-bar.collapse .chat-item-status {
    /* opacity: 0; */
    display: none;
    pointer-events: none;
  }
  .side-bar.collapse .message-text {
    opacity: 0;
    display: none;
    pointer-events: none;
    transform: translateX(0);
  }
  .side-bar.collapse .message-icon {
    transform: translateX(0);
  }
  .side-bar.collapse .logo-name__button {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translate(-50%,-50%);
    left: 65%;

  }
   
  .side-bar.collapse .chat-item-avatar-wrapper::before {
    opacity: 1;
  }

  .side-bar.collapse .features-item{
    /* padding: 0 0.6rem ;
    margin-bottom: 0.7rem; */
  }



 

@media (max-width:550px) {
  .side-bar {
   width: 100vw;

    
  }
  .side-bar.collapse{
    width: 3.4rem;
  }
  .features-item{
  }
  
  .features-item {
    
    min-height: 40px;
    max-height: 40px;
    height: 40px;
    padding: 0  !important;

  }


}
 

  