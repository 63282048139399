
  .searchContainer {
    display: flex;
    flex-direction: column;
    color: var(--mainBlack);
    width: 80%;
    margin: 0 auto;
    padding: 20px 40px;
  }
  #searchForm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }


  .searchRow {
    margin-bottom: 1%;
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 100%;
  }

  .searchColumn {
    display: flex;
    flex-direction: column;
    margin-right: 2%;
    min-height: 50px;
    max-height: 50px;
    height: 50px;
    width: 100%;
  }
  .searchField {
    padding: 18px 0;
    min-height: 50px;
    max-height: 50px;
    height: 50px;
    text-align: center;
    border-radius: 12px;
  }
  
  .searchButton {
    color: var(--white);
    background-color: var(--brand);
    min-height: 50px;
    max-height: 50px;
    height: 50px;
    display: flex;
    align-items: center;
  }
  .searchButton:disabled{
    background-color: var(--mainActive);
  }
  .searchButton:disabled:hover{
    background-color: var(--mainActive);
    border: 0px;

  }
  .FaIcon {
    margin-right: 5px;
  }
  
  .searchButton:hover {
    background-color: var(--brand);
    border: 1px solid var(--mainWhite);
  }

  .NoDataAvailable{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .NoDataAvailable img{
    height: 300px;
  }


  @media (max-width: 1180px) {
    .searchRow {
      width: 100%;
    }
    
  }

  @media (max-width: 700px) {
    .searchContainer {
      width: 95%;
      padding: 20px 20px;
    }
  }
  @media (max-width: 700px) {
    .searchButton,
    .searchResultBTN,
    .searchResultBTNactive {
      padding: 10px 20px !important;
    }
  }

  @media (max-width: 550px) {
    .searchContainer {
      padding: 5px;
    }
    .searchRow {
      width: 95%;
      padding: 0px;
      margin: 0 auto;
    }
    .row {
      flex-direction: column !important;
      justify-content: center;
      align-items: center;
    }
  }
  