.navBarRow_primary_sideBar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    min-width: 150px;
}




.navBarRow_primary_sideBar  > * + * {
    margin-top: 20px;
  }