.HeaderTitle,
.HeaderTitleAdmin {
    height: 100%;
    border: none;
    color: var(--brand);
    text-decoration: none;
    font-weight: 400;
    line-height: 140%;
    transition: 0.3s ease;
    padding: 10px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.HeaderTitleAdmin {
    color: var(--brand) !important;
}

.searchContainer_back_icon {
    display: flex;
    align-items: center;
    color: var(--mainCategoryViewAll) !important;
    /* margin-left: -200px; */
}
.searchContainer_back_icon:hover {
    cursor: pointer;
    color: var(--brand) !important;
}
.searchContainer_back {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.FaIcon {
    margin-right: 12px;
}

.FaIconSearch {
    color: var(--brandHover);
}

.FaIconSearch:hover {
    cursor: pointer;
    color: var(--brand);
}

/* // search stufff */
#searchForm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}
.searchRow {
    margin-bottom: 1%;
    display: flex;
    height: 50px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.searchColumn {
    display: flex;
    /* flex-direction: column; */
    min-height: 50px;
    max-height: 50px;
    height: 50px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: var(--mainWhite);
    width: 400px;
    outline: 1px solid var(--brand);
    max-width: 90%;
    border-radius: 30px;
}

.searchField {
    padding: 18px 0;
    min-height: 50px;
    max-height: 50px;
    height: 50px;
    text-align: center;
    /*border-radius: 2px;*/
    flex: 4;
    border: none;
    outline: none;
    color: var(--brand);
}

/* Placeholder color */
::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--brand);
}
::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--brand);
}
:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--brand);
}
:-moz-placeholder {
    /* Firefox 18- */
    color: var(--brand);
}

@media (max-width: 550px) {
    .HeaderTitle {
        padding: 10px 0px;
        flex-direction: column;
        gap: 20px;
    }
    .HeaderTitleAdmin {
        flex-direction: row;
    }

    .HeaderTitle > * + * {
        /* margin-top: 20px; */
    }

    #searchForm {
        width: 95%;
        margin: 0 auto;
    }
}
