.scoreboard-item--topctabutton {
    margin: 0;
    background: var(--cardbackground) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
    padding: 2px;
    border-radius: 12px;
}
.scoreboard-item--topcta {
    /* display: grid;
    /* grid-template-columns: 1fr 1fr 1fr; */
    /* grid-template-columns: 1fr 1fr ; */
    display: flex;
    /* gap: 20px; */
    justify-content: space-between;
}
.scoreboard-item--topcta_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.scoreboard-item--topcta_items {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: var(--modalBackground);
    padding: 10px;
    border-radius: 12px;
}
.scoreboard-item--topctabutton button {
    padding: 15px;
    background: black;
    color: white;
}

.ScoreboardItem {
    /* max-width: 600px; */
    display: flex;
    flex-direction: column;
    background-color: var(--card);
    padding: 50px 20px;
    gap: 20px;
    color: var(--mainWhite);
    /* padding-top: 0; */
    /* height: 500px; */
}

.bottomCTA {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    margin: 20px 0;
}

.ScoreboardItemHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    /* margin: 0 10px; */
}

.ScoreboardItemHeader img {
    height: 100px !important ;
    width: 178px !important;
    mix-blend-mode: multiply;
}

.ScoreboardItem input {
    /* height: 40px; */
    padding: 10px 10px;
    text-align: center;
    margin: 2px auto;
    font-size: 2em;
    width: 100%;
    text-align: center;
    padding: 0;
}

.teamNameButton button {
    padding: 10px;
    width: 100%;
    font-family: "UI-Black";
    align-self: center;
    font-size: 0.8em;
    border-radius: 0;
    background-color: #54af3c;
    /* background-color: #54af3c; */
    color: var(--mainWhite);
    font-weight: bolder;
}
.teamNameButton button:hover {
    opacity: 0.8;
    transition: all 0.5s ease-in-out;
}
.teamNameButton button:disabled {
    opacity: 0.5;
    background-color: grey !important;
}

.decreaseScoreButton {
    background-color: var(--red) !important;
}
.scoreboard-item--topcta_items_score {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 5px;
}
.scoreboard-item--topcta_items_button_item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: 2px;
    width: 100%;
}

.scoreboard-time {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    padding: 10px;
    background-color: var(--modalBackground);
    flex-direction: column;
}
.scoreboard-time-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
    width: 100%;
}

@media (max-width: 950px) {
    .ScoreboardItem {
        height: auto;
    }
}
@media (max-width: 550px) {
    .ScoreboardItem {
        padding: 10px;
    }
    .scoreboard-item--topcta {
        gap: 10px;
    }
}
