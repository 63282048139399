.Dashboard{

    display: flex;flex: 1;


}

.DashboardSideBar{
    /* flex: 1;
    background-color: var(--card);
    padding: 20px; */
    color: var(--mainWhite) !important;
    background-color: #17171e;


}


.DashboardContent{

    /* flex: 5; */
    background-color: var(--mainWhite);
    /* padding: 20px; */
    padding: 0 50px 50px 50px;
    flex: 1;
    /* margin-top: 50px; */
   

}



@media (max-width: 550px) {
    
    .DashboardContent{

        
        /* padding: 0 20px; */
        padding: 0;
        margin-top: 0;
        /* height: 100%; */
    
    }
    .DashboardSideBar{
       
        padding: 0;
       
    
    
    }
    
  }
   
   