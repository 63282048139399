/* html {
    font-size: 62.5%;
  }
   */
.input_text {
  font-family: inherit;

  /* padding: 1.5rem 2.5rem 0.5rem 1rem; */
  padding: 0.9rem;
  /* border: none; */
  border: 1px solid var(--brand);
  border-radius: 1rem;
  background: var(--mainWhite);
  width: 100%;
  appearance: none;
  height: 50px;
}
.input_text:focus {
  outline-color: var(--brand);
}

.inp {
  position: relative;
}
.inp input {
  padding: 1.5rem 2.5rem 0.5rem 1rem !important;
}
.select_label {
  position: relative;
}
.select_label::after {
  content: "";
  position: absolute;
  z-index: 20;
  right: 12px;
  top: 65%;
  margin-top: -3px;
  height: 0px;
  width: 0px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  pointer-events: none;
  border-top: 6px solid rgb(33, 37, 96);
}

.label {
  pointer-events: none;

  position: absolute;
  top: 1.3rem;
  left: 1rem;
  /* color: #00000070; */
  color: var(--brand);
  font-weight: 500;

  transition: all 0.2s;
  transform-origin: left;
  top: 50%;
  bottom: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input_text:not(:placeholder-shown) + .label,
.input_text:focus + .label {
  top: 0.3rem;
  transform: scale(0.8);
}
.input_text:focus + .label {
  color: var(--brand);
}

.input_icon {
  position: absolute;
  top: 1.3rem;
  right: 2rem;
  color: #00000070;
}

.input_icon_change {
  position: absolute;
  top: 0.7rem;
  /* right: 2rem; */
  right: 0;
  color: #00000070;
  cursor: pointer;
  top: 0.3rem;
}

.formPrimary {
  background-color: white;
  width: 100%;
  /* height: 100%; */
  min-height: 100%;
  width: 80%;
  margin-top: 50px;
}
.formScoreboard {
  /* width: 100%; */
  display: flex;
  gap: 20px;
}
.formPrimarySignIn {
  max-height: 600px;
  margin: 50px auto;
}
.formPrimary1 {
  background-color: var(--card);
  width: 100%;
  /* height: 100%; */
  /* min-height: 100%; */
  width: 60%;
  margin: 0 auto;
  /* margin-top: 50px; */
}
.formPrimary2 {
  min-height: 100% !important;
  height: 100%;
  max-height: 100%;
}

/* // for image and form to align flex */
.formSecondary {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  min-height: 100%;
}

/* // image  */
.formSecondary img {
  height: 60vh;
  width: 40vw;
  display: flex;
}
.formBlogAdmin {
  width: 100%;
  height: auto;

  padding-bottom: 50px;

  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: var(--mainWhite);
  margin: 0 20% 0 50px;
}
.FormAdmin {
  background-color: var(--mainWhite);
  padding: 0;
}
.formBlog {
  width: 50%;
  height: auto;
  margin: 0 20% 0 20%;
  /* border: 1px solid var(--card);
    background-color: var(--card); */
  padding: 0 20px 20px 20px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  min-height: 100%;
  /* margin: 50px 0; */
  margin: 0 auto;
}
.formBlogAdmin2 {
  background-color: #dfdfdf;
  border-radius: 10px;
}

/* // h2 */
.formHeader {
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
}
.formHeader h2 {
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
}
/* Guest: Ανδρέας Χαραλάμπους, Ιδρυτής της Capsule Skateboards */
.formHeader::after {
  background-color: var(--brand);
}
/* // invalid form input */
.formInputInvalid input {
  outline-color: var(--red);
  border: 2px solid var(--red) !important;
}
/* // error text */
.errorText {
  color: var(--red);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -15px;
}
.errorText::before {
  /* display: inline; */
  content: "⚠  \00a0  ";
}

.loginRow {
  flex-direction: column;
  color: var(--mainBlack);
}
.loginRowAdmin {
  flex-direction: column;
  color: var(--brandHover);
}
.loginRow > * + * {
  margin-top: 10px;
}
.loginRow a {
  text-decoration: none;
  color: var(--brand);
  border-bottom: 1px solid transparent;
}
.loginRow a:hover {
  border-bottom: 1px solid var(--brand);
}
@media (max-width: 950px) {
  .formScoreboard {
    flex-direction: column;
  }
}
@media (max-width: 550px) {
  .input_text {
    height: 40px;
  }
  .input_text {
    padding: 0.5rem 1rem;
  }

  .input_icon_change {
    top: 0;
  }
  .formBlogAdmin {
    padding: 0;
    padding-bottom: 50px;
    padding: 2.5%;
  }
  .label {
    /* top: 0.8rem; */
  }
  .formBlog {
    width: 100%;
    margin: 0 auto;
  }
  .formPrimary {
    width: 100%;

    margin-top: 0;
  }
  .formPrimary1 {
    width: 100%;
  }
  .formBlogAdmin2 {
    border-radius: 0;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px var(--mainWhite) inset; /* Change the color to your own background color */
  -webkit-text-fill-color: var(--mainWhite);
}
