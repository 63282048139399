 
.ListAllSubOrg_item{
    /* padding: 20px 20px; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    min-height: 350px;
    background-color: var(--brand);
    border-radius: 50px;
    margin: 0 20px;
    margin-top: 20px;

}
.primaryContainer,.tertiaryContainer{
    width: 88%;
    margin: 0 auto;
}
.secondaryContainer{
    background-color: var(--mainWhite);
   margin:50px 0;
   padding: 20px 0;
}


.container{
    display: grid;
    grid-template-columns: 0.1fr 4fr;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.line{
    height: 2px;
    color: white;
    width :100%;

    background-color: var(--brand);
    
}



.ListAllSubOrg_name{
    color: var(--mainWhite);
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 20px 0; */
    padding: 20px 0;
    text-transform: uppercase;
    text-align: center;
}


.ListAllSubOrg_image{
  /* height: 290px; */
  width: 100%;
}

.ListAllSubOrg_image:hover{
    cursor: pointer;
}

.allLetter{
    display: flex;
    justify-content: space-around
    ;
    align-items: center;
    margin: 70px auto 30px auto;
    flex-flow: row wrap;
    font-weight: 800;
}
.allLetter li {
    text-decoration: none;
    list-style: none;
    padding: 2px 8px;
    cursor: pointer;
    color: var(--brand);

}
.allLetter li a {
    text-decoration: none;
    color: var(--brand);

}

 
.allLetter_alphabet{
 
    margin: 50px auto;
    padding: 20px 0;
    /* margin-top: 900px; */


    /* test */
/* 
    gap: 600px;
    display: flex;
    flex-direction: column; */
}
.allLetter_alphabet a{
    color: var(--mainBlack) !important;
    text-decoration: none;
}
.allLetter_alphabet_primary{
    display: flex;
    /* border-top: 1px solid #e3e3e3; */
    width: 100%;

    padding: 20px 10px;

    flex-direction: column;

}

 
.item_alphabet{
    font-weight: 800;
    /* min-width: 150px; */
    /* flex  :2; */
    color: var(--brand);
    width: 10px;

    
}
.item_content:hover{
    color: var(--card);
    cursor: pointer;
    transition: all 0.3s ease-in-out;

}
.allLetter_alphabet_item_content{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: start;

    align-items: center;
    gap: 30px;
    flex: 5;
    text-transform: uppercase;
    
    
}
.allLetter_alphabet_item >*+*{

   margin-left: 150px;
}
.allLetter_alphabet_item a{
    color: var(--mainBlack);
    text-decoration: none;
}
 
.allLetter_disabled{
    color: var(--mainWhite);
    pointer-events: none;
}
.allLetter_disabled a{
    color: var(--mainBlack) !important;
    pointer-events: none;
}
.item_content{
    color: var(--mainBlack);
}
 

@media (max-width: 550px){
    .allLetter_alphabet_item_content{
       
        grid-template-columns: 1fr 1fr ;
        
        row-gap: 30px;
        column-gap: 10px;
        
        
    }
    .allLetter_alphabet_primary{
        flex-direction: column;

    
        padding: 20px 0px;
    
    }
    
    
    .allLetter_alphabet_item{

       flex-direction: column;
       padding: 20px 5px;

    }.allLetter_alphabet_item > * + *{

     margin-top: 20px;
     margin-left: 0;
       
    }
    .ListAllSubOrg_item{
    margin: 0;
        
    }
     
    .primaryContainer,.tertiaryContainer{
        width: 94%;
    }
    .container{
        grid-template-columns: 0.2fr 4fr;
        
    }
 

}

